import axios from 'axios'

import { Config, Header } from '../config.api'

const getUser = async (id) => {
  const filter = `[{"field":"id","type":"number","value":"${id}"}]`

  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/user?filter=${filter}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
export default { getUser }
