import axios from 'axios'
import { Config, Header } from '../../config.api'

const GetListDataActivity = async (skip, take, filter) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/activities?skip=${skip}&take=${take}&filter=${filter}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const GetListCategory = async () => {
  try {
    const response = await axios.get(`${Config.MAIN_SERVICE_ENDPOINT}/enumactivity`, await Header())
    return response
  } catch (error) {
    return error
  }
}

const GetDetailDataActivity = async (id) => {
  try {
    const response = await axios.get(`${Config.MAIN_SERVICE_ENDPOINT}/activity/${id}`, await Header())
    return response
  } catch (error) {
    return error
  }
}

const PostDataActivity = async (formData) => {
  try {
    const response = await axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/activity/insert`,
      formData,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

export const EditDataActivity = async (id, formData) => {
  try {
    const response = await axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/activity/update/${id}`,
      formData,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

export const DelDataActivity = async (id) => {
  try {
    const response = await axios.delete(
      `${Config.MAIN_SERVICE_ENDPOINT}/activity/delete/${id}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

export const GetDataCategoryPercent = async () => {
  try {
    const response = await axios.get(`${Config.MAIN_SERVICE_ENDPOINT}/activity_category`, await Header())
    return response
  } catch (error) {
    return error
  }
}

export default {
  GetListCategory,
  DelDataActivity,
  PostDataActivity,
  EditDataActivity,
  GetListDataActivity,
  GetDetailDataActivity,
  GetDataCategoryPercent,
}
