import axios from 'axios'

import { Actions } from '../../utils'
import { Config, Header } from '../config.api'

const getAllUnAllocated = async (skip, take, filters, location) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/service_request_executions/${location}?skip=${skip}&take=${take}&filter=${filters}&order=Eta|asc`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const getAllJettySch = async (location) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/jetty/${location}?skip=0&take=1000&filter=[]`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const getAllJetty = async (location) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/jetty/${location}?skip=0&take=1000&filter=[]`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const getAllScheduler = async (view, date, location) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedules/${location}?filter=[{"type": "${view}", "start" : "plan_arrival", "end" : "plan_depart", "value": "${date}"}]`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const unAllocatedToScheduler = async (formData) => {
  try {
    const response = await axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/update_drag`,
      formData,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const resizeAndDrag = async (formData) => {
  try {
    const response = await axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/update_size`,
      formData,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const backToUnAllocated = async (formData) => {
  try {
    const response = await axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/cancel`,
      formData,
      await Header()
    )
    return response
  } catch (eror) {
    return eror
  }
}
const EditTabSr = async (formData) => {
  try {
    const response = await axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/sr/update`,
      formData,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const EditGridActivity = async (formData) => {
  try {
    const response = await axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/activity/update`,
      formData,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const updateTabExecution = async (formData) => {
  try {
    const response = await axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/update`,
      formData,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const updateDelayGrids = async (formData) => {
  try {
    const response = await axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/delay/insert`,
      formData,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const editDelayGrids = async (formData) => {
  try {
    const response = await axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/delay/update`,
      formData,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const deleteDelayGrids = async (delData) => {
  try {
    const response = await axios.delete(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/delay/delete/${delData}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const getDataExecution = async (code) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/detail/${code}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const getTaskExecution = async (code, actvCode) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/tasks/${code}/${actvCode}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const updateActivityTask = async (formData) => {
  try {
    const response = await axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/progress_task/update`,
      formData,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const deleteActivityTask = async (delData) => {
  try {
    const response = await axios.delete(
      `${Config.MAIN_SERVICE_ENDPOINT}/progress_task/delete/${delData}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const deleteAttachmentExct = async (delData) => {
  try {
    const response = await axios.delete(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/attachment/delete/${delData}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const deleteAttachmentExctByCode = async (attachmentCode) => {
  try {
    const response = await axios.delete(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/attachment/new-delete/${attachmentCode}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const getExecutionProduct = async (code, srCode) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/sr_products/${code}?skip=0&take=40&filter=[{"type": "text", "field" : "sr_category_status", "value": "${srCode}"}]&order=id|asc`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const getInboundStatus = async (code) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/inbound/${code}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const getListActivity = async (code) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/activities/${code}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const getTransActivity = async (code) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/activity_progress/${code}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const getDelayType = async () => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/delay_type`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const getDescDelay = async (code) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/delay_desc/${code}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const getPosition = async (code) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/jetty/${code}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const getDelayGrids = async (code) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/delays/${code}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const getAttachmentGrids = async (skip, take, filter) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/attachments?skip=${skip}&take=${take}&filter=${filter}`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}
const GetAttachmentFile = async (filename) => {
  try {
    const response = await axios.get(
      `${
        Config.ATTACHMENT_SERVICE_ENDPOINT
      }/uploader/download?filename=${encodeURIComponent(filename)}`
    )
    return response.data
  } catch (error) {
    return error
  }
}

const GetAttachmentFileSR = async (filename) => {
  try {
    const response = await axios.get(
      `${
        Config.ATTACHMENT_SERVICE_ENDPOINT
      }/uploader/download-sr?filename=${encodeURIComponent(filename)}`
    )
    return response.data
  } catch (error) {
    return error
  }
}

const GetAllFromTableEnum = async (skip, take, filters) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/enums?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const UploadAttachments = async (data) => {
  return axios.post(
    `${Config.ATTACHMENT_SERVICE_ENDPOINT}/uploader/multiple`,
    data,
    await Header()
  )
}

const InsertAlldoc = async (data) => {
  try {
    const response = await axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/alldoc/insert`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const InsertAllAttachment = async (data) => {
  try {
    const response = await axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/attachment/insert`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const InsertAllAttachmentNew = async (data) => {
  try {
    const response = await axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/attachment/new-insert`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetDataCLients = async () => {
  try {
    const filter = '[{"field":"is_active","type":"bool","value":"true"}]'
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/clients?skip=0&take=1000&filter=${filter}`,

      await Header()
    )
    return response
  } catch (error) {
    console.log(error)
    return error
  }
}
const GetDataLocations = async () => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/locations?skip=0&take=1000&filter=[]`,

      await Header()
    )
    return response
  } catch (error) {
    console.log(error)
    return error
  }
}
const GetDataCategory = async () => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/sr_category?skip=0&take=1000&filter=[]`,
      await Header()
    )
    return response
  } catch (error) {
    console.log(error)
    return error
  }
}
const insertActivityTask = async (formData) => {
  try {
    const response = await axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/progress_task/insert`,
      formData,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const GetDataPrintOut = async (data) => {
  try {
    const response = await axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/printouts`,
      data,
      await Header()
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

const getAllExecutions = async (skip, take, location, filters) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/executions/${location}? skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const getAllNotification = async (filters) => {
  try {
    const response = await axios.get(
      `${Config.WORKFLOW_SERVICE_ENDPOINT}/notification/detail?filter=${filters}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const GetColumnOption = async () => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/column_option/v_t_executions`
    const response = await axios.get(url, await Header())
    return response
  } catch (error) {
    console.log(error)
    return error
  }
}

const UpdateColumnOption = async (columns) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/column_option`
    const response = await axios.post(url, columns, await Header())
    return response
  } catch (error) {
    console.log(error)
    return error
  }
}

const InboundToPBMS = async (srCode) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/inbound_pbms_msr/${srCode}`
    const response = await axios.get(url, await Header())
    return response
  } catch (error) {
    return error
  }
}
const ConfirmPlanningNotif = async (formData) => {
  try {
    const response = await axios.put(
      `${Config.WORKFLOW_SERVICE_ENDPOINT}/approval/confirmExecutionStatus`,
      formData,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}
const getConfirmation = async (srCode) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/transact_schedule/notification/${srCode}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const getCargoData = async (sr) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/cargo/${sr}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const getEquipmentData = async (sr) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/estimation/${sr}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

// =========== NEW API ===========
const getActivityList = async (srCode) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-activity`
    const requestPayload = {
      action: Actions.GET_PROGRESS_ACTIVITY_BY_SR_CODE_COMPLETE_DATA,
      activity_payload: {
        sr_code: srCode
      }
    }
    return await axios.post(url, requestPayload, await Header())
  } catch (error) {
    return error
  }
}

const updateProgressActivity = async (activityPayload) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-activity`
    const requestPayload = {
      action: Actions.UPDATE_PROGRESS_ACTIVITY,
      activity_payload: activityPayload
    }

    return await axios.post(url, requestPayload, await Header())
  } catch (error) {
    throw new Error('updateProgressActivity is not OK => ' + error.toString())
  }
}

const updateActivityAndTaskHeader = async (
  activityPayload,
  taskHeaderPayload
) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-activity`
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.UPDATE_ACTIVITY_AND_TASK_HEADER,
      activity_payload: {
        ...activityPayload,
        modified_by: requestHeader.headers.Email
      },
      task_header_payload: {
        ...taskHeaderPayload,
        modified_by: requestHeader.headers.Email
      }
    }

    return await axios.post(url, requestPayload, requestHeader)
  } catch (error) {
    throw new Error(
      'updateActivityAndTaskHeader is not OK => ' + error.toString()
    )
  }
}

const insertProgressTaskHeader = async (taskHeaderData) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-task`
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.INSERT_PROGRESS_TASK_HEADER,
      task_header_data: {
        ...taskHeaderData,
        created_by: requestHeader.headers.Email,
        modified_by: requestHeader.headers.Email
      }
    }

    return await axios.post(url, requestPayload, requestHeader)
  } catch (error) {
    throw new Error('insertProgressTaskHeader is not OK => ' + error.toString())
  }
}

const updateProgressTaskHeader = async (taskHeaderData) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-task`
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.UPDATE_PROGRESS_TASK_HEADER,
      task_header_data: {
        ...taskHeaderData,
        modified_by: requestHeader.headers.Email,
        modified_date: new Date()
      }
    }

    return await axios.post(url, requestPayload, requestHeader)
  } catch (error) {
    throw new Error('updateProgressTaskHeader is not OK => ' + error.toString())
  }
}

const deleteProgressTaskHeader = async (taskId, modifiedDate) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-task`
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.DELETE_PROGRESS_TASK_HEADER,
      task_header_data: {
        id: taskId,
        modified_by: requestHeader.headers.Email,
        modified_date: modifiedDate
      }
    }

    return await axios.post(url, requestPayload, requestHeader)
  } catch (error) {
    throw new Error('deleteProgressTaskHeader is not OK => ' + error.toString())
  }
}

const getProgressTaskDetailByTaskHeaderCode = async (activityCode, taskHeaderCode) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-task`
    const requestPayload = {
      action: Actions.GET_PROGRESS_DETAIL_BY_HEADER_CODE,
      task_detail_data: {
        activity_code: activityCode,
        task_header_code: taskHeaderCode
      }
    }
    return await axios.post(url, requestPayload, await Header())
  } catch (error) {
    return error
  }
}

const insertProgressTaskDetail = async (taskDetailData) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-task`
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.INSERT_PROGRESS_TASK_DETAIL,
      task_detail_data: {
        ...taskDetailData,
        created_by: requestHeader.headers.Email,
        created_date: new Date(),
        modified_by: requestHeader.headers.Email,
        modified_date: new Date()
      }
    }

    return await axios.post(url, requestPayload, requestHeader)
  } catch (error) {
    throw new Error('insertProgressTaskDetail is not OK => ' + error.toString())
  }
}

const updateProgressTaskDetail = async (taskDetailData) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-task`
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.UPDATE_PROGRESS_TASK_DETAIL,
      task_detail_data: {
        ...taskDetailData,
        modified_by: requestHeader.headers.Email,
        modified_date: new Date()
      }
    }

    return await axios.post(url, requestPayload, requestHeader)
  } catch (error) {
    throw new Error('updateProgressTaskDetail is not OK => ' + error.toString())
  }
}

const deleteProgressTaskDetail = async (taskDetailId) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-task`
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.DELETE_PROGRESS_TASK_DETAIL,
      task_detail_data: {
        id: taskDetailId,
        modified_date: new Date(),
        modified_by: requestHeader.headers.Email
      }
    }

    return await axios.post(url, requestPayload, requestHeader)
  } catch (error) {
    throw new Error('deleteProgressTaskDetail is not OK => ' + error.toString())
  }
}

const InsertCargo = async (data) => {
  try {
    const response = await axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/cargo/InsertCargo`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const EditCargo = async (data) => {
  try {
    const response = await axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/cargo/UpdateCargo`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const EditCargoNo = async (data) => {
  try {
    const response = await axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/cargo/UpdateCargoNo`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const InsertEquipment = async (data) => {
  try {
    const response = await axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/estimation/InsertEstimation`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const DeleteCargo = async (dataRemove) => {
  try {
    const response = await axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/cargo/DeleteCargo`,
      dataRemove,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const DeleteMultipleCargo = async (dataRemove) => {
  try {
    const response = await axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/cargo/DeleteMultipleCargo`,
      dataRemove,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const DeleteEquipment = async (dataRemove) => {
  try {
    const response = await axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/estimation/DeleteEstimation`,
      dataRemove,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const EditEquipment = async (data) => {
  try {
    const response = await axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/estimation/UpdateEstimation`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetSRFieldMapping = async (data) => {
  try {
    const response = await axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/administrative-tools`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const getProgressActivityTaskHeaderDelay = async (srCode) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-activity`
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.GET_PROGRESS_ACTIVITY_TASK_HEADER_DELAY,
      activity_payload: {
        sr_code: srCode
      }
    }
    return await axios.post(url, requestPayload, requestHeader)
  } catch (error) {
    return error
  }
}

const getProgressDelayByActivityAndTaskHeader = async (delayData) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-delay`
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.GET_PROGRESS_DELAY_BY_TASK_HEADER_CODE,
      data: delayData
    }
    return await axios.post(url, requestPayload, requestHeader)
  } catch (error) {
    return error
  }
}

const insertProgressDelay = async (delayData) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-delay`
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.INSERT_PROGRESS_DELAY,
      data: {
        ...delayData,
        created_by: requestHeader.headers.Email,
        modified_by: requestHeader.headers.Email,
        created_date: new Date(),
        modified_date: new Date()
      }
    }

    return await axios.post(url, requestPayload, requestHeader)
  } catch (error) {
    return error
  }
}

const updateProgressDelay = async (delayData) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-delay`
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.UPDATE_PROGRESS_DELAY,
      data: {
        ...delayData,
        modified_by: requestHeader.headers.Email,
        modified_date: new Date()
      }
    }

    return await axios.post(url, requestPayload, requestHeader)
  } catch (error) {
    return error
  }
}

const deleteProgressDelay = async (delayId) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-delay`
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.DELETE_PROGRESS_DELAY,
      data: {
        id: delayId,
        modified_by: requestHeader.headers.Email,
        modified_date: new Date(),
        deleted_by: requestHeader.headers.Email,
        deleted_date: new Date()
      }
    }
    return await axios.post(url, requestPayload, requestHeader)
  } catch (error) {
    return error
  }
}

const getAllMasterUnit = async (query) => {
  try {
    const url = `${Config.LINTAS_API}/lintas-sr-master-data/master-equipment`
    const request = {
      action: Actions.GET_ALL_MASTER_EQUIPMENT,
      query: query
    }

    const responses = await axios.post(url, request, await Header())
    return responses
  } catch (error) {
    return error
  }
}

const getEquipmentLabourBySRCode = async (srCode) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-activity`
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.GET_EQUIPMENT_LABOUR_BY_SR_CODE,
      activity_payload: {
        sr_code: srCode
      }
    }
    return await axios.post(url, requestPayload, requestHeader)
  } catch (error) {
    return error
  }
}

const handleCheckProgressActivity = async (activityData) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-activity`
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.HANDLE_CHECK_PROGRESS_ACTIVITY,
      activity_payload: {
        ...activityData,
        modified_by: requestHeader.headers.Email
      }
    }

    return await axios.post(url, requestPayload, requestHeader)
  } catch (error) {
    return error
  }
}

const ExportExcelCargo = async (data) => {
  try {
    const url = `${Config.LINTAS_API}/lintas-sr-planning/planning`
    const request = {
      action: Actions.EXPORT_EXCEL_CARGO,
      data: data
    }
    const responses = await axios.post(url, request, await Header())
    return responses
  } catch (error) {
    return error
  }
}

const ImportExcelCargo = async (data) => {
  try {
    const url = `${Config.LINTAS_API}/lintas-sr-planning/planning`
    const request = {
      action: Actions.IMPORT_EXCEL_CARGO,
      data: data
    }
    const responses = await axios.post(url, request, await Header())
    return responses
  } catch (error) {
    return error
  }
}

const UploadExcelCargo = async (formData) => {
  try {
    const headers = await Header()
    const response = await axios.post(
      `${Config.LINTAS_API}/lintas-sr-planning/uploader`,
      formData,
      {
        ...headers,
        'Content-Type': 'multipart/form-data'
      }
    )
    return response
  } catch (error) {
    return error
  }
}

const updateTaskHeaderSortNo = async (taskHeaderList) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-task`
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.UPDATE_TASK_HEADER_SORT_NO,
      update_sort_no_data: taskHeaderList
    }

    return await axios.post(url, requestPayload, requestHeader)
  } catch (error) {
    return error
  }
}

const GetListCCU = async (code) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/ccu`
    const request = {
      action: Actions.GET_ALL_CCU,
      data: { sr_code: code }
    }
    const responses = await axios.post(url, request, await Header())
    return responses
  } catch (error) {
    return error
  }
}

const GetCCUHired = async (data) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/ccu`
    const request = {
      action: Actions.GET_ALL_CCU_HIRED,
      data: data
    }
    const responses = await axios.post(url, request, await Header())
    return responses
  } catch (error) {
    return error
  }
}

const GetCCUUnit = async (location) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/ccu`
    const request = {
      action: Actions.GET_ALL_CCU_UNIT,
      data: { location_code: location }
    }
    const responses = await axios.post(url, request, await Header())
    return responses
  } catch (error) {
    return error
  }
}

const InsertUpdateCCU = async (data) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/ccu`
    const request = data
    const responses = await axios.post(url, request, await Header())
    return responses
  } catch (error) {
    return error
  }
}

const GetListWorkPermit = async (code) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/work_permit`
    const request = {
      action: Actions.GET_ALL_WORK_PERMIT,
      data: { sr_code: code }
    }
    const responses = await axios.post(url, request, await Header())
    return responses
  } catch (error) {
    return error
  }
}

const InsertUpdateWorkPermit = async (data) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/work_permit`
    const request = {
      action: Actions.UPSERT_WORK_PERMIT,
      data: data
    }
    const responses = await axios.post(url, request, await Header())
    return responses
  } catch (error) {
    return error
  }
}

const InsertUpdateWorkPermitList = async (data) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/work_permit`
    const request = {
      action: 'UPSERT_WORK_PERMIT_LIST',
      list: data
    }
    const responses = await axios.post(url, request, await Header())
    return responses
  } catch (error) {
    return error
  }
}

const getMasterDelayType = async () => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-delay`
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.GET_MASTER_DELAY_TYPE
    }
    return await axios.post(url, requestPayload, requestHeader)
  } catch (error) {
    return error
  }
}

const getMasterDelay = async (delayTypeCode) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-delay`
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.GET_MASTER_DELAY,
      delay_type_code: delayTypeCode
    }
    return await axios.post(url, requestPayload, requestHeader)
  } catch (error) {
    return error
  }
}

const updateTaskHeaderSort = async (order, data) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-task`
    const requestHeader = await Header()
    const requestPayload = {
      Action: Actions.SORT_AUTO_UPDATE_TASK_HEADER,
      Query: {
        Orders: order,
      },
      Task_header_data: data
    }

    return await axios.post(url, requestPayload, requestHeader)
  } catch (error) {
    return error
  }
}
export default {
  EditTabSr,
  EditCargo,
  GetCCUUnit,
  GetListCCU,
  GetCCUHired,
  getAllJetty,
  DeleteCargo,
  EditCargoNo,
  InsertCargo,
  getPosition,
  getCargoData,
  getDescDelay,
  getDelayType,
  InsertAlldoc,
  EditEquipment,
  InboundToPBMS,
  getDelayGrids,
  resizeAndDrag,
  editDelayGrids,
  GetDataCLients,
  getAllJettySch,
  DeleteEquipment,
  InsertUpdateCCU,
  InsertEquipment,
  getConfirmation,
  GetColumnOption,
  GetDataCategory,
  getAllScheduler,
  GetDataPrintOut,
  getListActivity,
  ImportExcelCargo,
  ExportExcelCargo,
  UploadExcelCargo,
  GetListWorkPermit,
  getEquipmentData,
  getAllExecutions,
  getInboundStatus,
  getTaskExecution,
  getDataExecution,
  GetDataLocations,
  getTransActivity,
  deleteDelayGrids,
  EditGridActivity,
  updateDelayGrids,
  getAllUnAllocated,
  backToUnAllocated,
  UploadAttachments,
  GetAttachmentFile,
  getAllNotification,
  UpdateColumnOption,
  insertActivityTask,
  updateActivityTask,
  deleteActivityTask,
  getAttachmentGrids,
  updateTabExecution,
  InsertAllAttachment,
  getExecutionProduct,
  DeleteMultipleCargo,
  GetAllFromTableEnum,
  ConfirmPlanningNotif,
  deleteAttachmentExct,
  updateTaskHeaderSort,
  InsertUpdateWorkPermit,
  unAllocatedToScheduler,
  InsertAllAttachmentNew,
  deleteAttachmentExctByCode,
  InsertUpdateWorkPermitList,

  getMasterDelay,
  getActivityList,
  getAllMasterUnit,
  GetSRFieldMapping,
  getMasterDelayType,
  insertProgressDelay,
  updateProgressDelay,
  deleteProgressDelay,
  updateProgressActivity,
  updateTaskHeaderSortNo,
  insertProgressTaskHeader,
  updateProgressTaskHeader,
  deleteProgressTaskHeader,
  insertProgressTaskDetail,
  updateProgressTaskDetail,
  deleteProgressTaskDetail,
  getEquipmentLabourBySRCode,
  handleCheckProgressActivity,
  updateActivityAndTaskHeader,
  getProgressActivityTaskHeaderDelay,
  getProgressTaskDetailByTaskHeaderCode,
  getProgressDelayByActivityAndTaskHeader,

  GetAttachmentFileSR
}
