import axios from 'axios'
import { Actions } from '../../utils'
import { Config, Header }  from '../config.api'


const GetMasterDataType= async ()=>{
  try {
    const response = await axios.post(
            `${Config.LINTAS_API}/lintas-master-data/master-uom`,
            {
              action: 'GET_ALL_MASTER_UOM',
            },
            await Header()
    )
    return response
  } catch (error) {
    return { error }
  }
}

const GetMasterDataLocation = async () => {
  try {
    const response = await axios.post(
          `${Config.LINTAS_API}/lintas-lbs-master-data/master_data`,
          {
            action: Actions.GET_ALL_LOCATION
          },
          await Header()
    )

    return response
  } catch (error) {
    return { error }
  }
}
const GetFilteredMasterDataEnum = async (value) => {
  try {
    const response = await axios.post(
            `${Config.LINTAS_API}/lintas-lbs-master-data/master_data`,
            {
              action: 'GET_ALL_MASTER_ENUM',
              query: {
                Skip: 0,
                Take : 10,
                Filters: [
                  {
                    'type':'text',
                    'field':'type',
                    'value': `${value}`
                  }
                ],
                Orders: []
              }
            },
            await Header()
    )
    return response.data
  } catch (error) {
    return { error }
  }
}

const GetMasterProductType= async () => {
  try {
    const response = await axios.post(
            `${Config.LINTAS_API}/lintas-sr-master-data/master-product-type`,
            {
              action: 'GET_ALL_MASTER_PRODUCT_TYPE',
            },
            await Header()
    )
    return response
  } catch (error) {
    return { error }
  }
}

const GetMasterContractDetails = async (contract)=>{
  try {
    const response = await axios.post(
        `${Config.MAIN_SERVICE_ENDPOINT}/master_data`,
        {
          action: 'GET_ALL_CONTRACT_DETAILS',
          Query: {
            Filters: [{
              field: 'sales_document_no',
              type: 'text',
              value: `${contract}`
            }],
          }
        },
        await Header()
    )
    return response
  } catch (error) {
    return { error }
  }
}

const GetMasterContractDetailsWithMaterial = async (query)=>{
  try {
    const response = await axios.post(
        `${Config.MAIN_SERVICE_ENDPOINT}/master_data`,
        {
          action: 'GET_ALL_CONTRACT_DETAILS_WITH_MATERIAL',
          Query: query
        },
        await Header()
    )
    return response
  } catch (error) {
    return { error }
  }
}

const GetMasterDataAgent = async () => {
  try {
    const response = await axios.post(
            `${Config.LINTAS_API}/auth/agents`,
            {
              action: 'GET_ALL',
              Query : {
                Filters: [],
                Order: 'Code ASC',
                Skip: 0,
                Take:1000
              }
            },
            await Header()
    )
    return response
  } catch (error) {
    return { error }
  }
}

const GetListMasterData = async (code) => {
  try {
    const response = await axios.post(
            `${Config.LINTAS_API}/lintas-sr-master-data/master-data`,
            {
              action: 'GET_ALL_MASTER_DATA',
              Query: {
                Skip: 0,
                Take: 100,
                Filters: [{
                  value: `${code}`,
                  type: 'int',
                  field: 'location_code'
                }],
                Orders: []
              }
            },
            await Header()
    )
    return response
  } catch (error) {
    return { error }
  }
}

const GetMasterRecipient = async () => {
  try {
    const response = await axios.post(
        `${Config.LINTAS_API}/lintas-master-data/master-recipient`,
        {
          action: 'GET_ALL_MASTER_RECIPIENT',
        },
        await Header()
    )
    return response
  } catch (error) {
    return { error }
  }
}

const GetMasterDataPort = async (code) => {
  try {
    const response = await axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/master_data`,
      {
        action: Actions.GET_ALL_MASTER_DATA,
        data: {
          location_id: code
        }
      },
      await Header()
    )
    return response
  } catch (error) {
    return { error }
  }
}

const GetContractTitle = async (sap_no) => {
  try{
    const response = await axios.post(
          `${Config.LINTAS_API}/auth/po-list`,
          {
            Action: Actions.GET_ALL_CONTRACT_TITLES,
            query: {
              filters: [
                {
                  field: 'sap_contract_no',
                  type: 'text',
                  value: sap_no
                }
              ],
              skip: 0,
              orders: [],
              take: 100
            }
          },
          await Header()
    )
    return response
  } catch (error) {
    return{ error }
  }
}

export default {
  GetContractTitle,
  GetMasterDataPort,
  GetMasterDataType,
  GetListMasterData,
  GetMasterRecipient,
  GetMasterDataAgent,
  GetMasterProductType,
  GetMasterDataLocation,
  GetMasterContractDetails,
  GetFilteredMasterDataEnum,
  GetMasterContractDetailsWithMaterial,
}
