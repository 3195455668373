import { SET_COLUMNS, RESET_COLUMNS, SET_FILTER_TABULAR, RESET_FILTER_COLUMNS } from '../../actions'

const initialState = {
  showColumns: [],
  hideColumns: [],
  filterTabular: {}
}

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
  case SET_COLUMNS: {
    return {
      ...state,
      showColumns: action.payload.showColumns,
      hideColumns: action.payload.hideColumns
    }}
  case RESET_COLUMNS: {
    return {
      ...state,
      showColumns: [],
      hideColumns: []
    }
  }
  case SET_FILTER_TABULAR: {
    return {
      ...state,
      filterTabular: action.payload
    }}
  case RESET_FILTER_COLUMNS: {
    return {
      ...initialState
    }}
  default: {
    return state
  }
  }
}

export default reportReducer