import Axios from 'axios'
import { Config, Header } from '../../config.api'

const GetAllMasterDataUom = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/uoms?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetListUomType = async () => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/enums?take=10&skip=0&filter=[{"type": "text", "field": "type", "value": "UOM_Type"}]`,
      await Header()
    )
    return response
  }catch (error) {
    return error
  }
}

const InsertUom = async (data) => {
  try {
    const response = await Axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/uom/insert`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const UpdateUom = async (data) => {
  try {
    const response = await Axios.put(`${Config.MAIN_SERVICE_ENDPOINT}/uom/update`, data, await Header())
    return response.data
  } catch (error) {
    return error
  }
}

const DeleteUom = async (id) => {
  try {
    const response = await Axios.delete(
      `${Config.MAIN_SERVICE_ENDPOINT}/uom/delete/${id}`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

export default { GetAllMasterDataUom, GetListUomType, InsertUom, UpdateUom, DeleteUom }
