import { SET_WINDOW_SIZE } from '../../actions'
const initialState = {
  width: '100vw',
  height: '100vh',
}

const appsReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
  case SET_WINDOW_SIZE: {
    return { ...action.payload }
  }
  default: {
    return state
  }
  }
}

export default appsReducer
