import { GET_CATEGORIES, GET_ACTIVITY_LIST, GET_ACTIVITY_DETAIL } from '../../../actions'
const initialState = {
  dataActivity: [],
  dataCategory : [],
  dataDetail : {},
  dataCount : 0
}

const activityReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
  case GET_ACTIVITY_LIST: {
    return {
      ...state,
      dataActivity : action.payload.data,
      dataCount : action.payload.count
    }}
  case GET_CATEGORIES: {
    return {
      ...state,
      dataCategory : action.payload
    }}
  case GET_ACTIVITY_DETAIL: {
    return {
      ...state,
      dataDetail : action.payload
    }}
  default: {
    return state
  }
  }
}

export default activityReducer
