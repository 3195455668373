// Actions for Execution
const UPDATE_PROGRESS_ACTIVITY = 'UPDATE_PROGRESS_ACTIVITY'
const INSERT_PROGRESS_TASK_HEADER = 'INSERT_PROGRESS_TASK_HEADER'
const UPDATE_PROGRESS_TASK_HEADER = 'UPDATE_PROGRESS_TASK_HEADER'
const DELETE_PROGRESS_TASK_HEADER = 'DELETE_PROGRESS_TASK_HEADER'
const HANDLE_CHECK_PROGRESS_ACTIVITY = 'HANDLE_CHECK_PROGRESS_ACTIVITY'
const UPDATE_ACTIVITY_AND_TASK_HEADER = 'UPDATE_ACTIVITY_AND_TASK_HEADER'
const UPDATE_ACTIVITY_AND_TASK_HEADER_FOR_SETTLEMENT = 'UPDATE_ACTIVITY_AND_TASK_HEADER_FOR_SETTLEMENT'
const GET_PROGRESS_ACTIVITY_BY_SR_CODE_COMPLETE_DATA = 'GET_PROGRESS_ACTIVITY_BY_SR_CODE_COMPLETE_DATA'
const UPDATE_ACTIVITY_SETTLEMENT = 'UPDATE_ACTIVITY_SETTLEMENT'

const INSERT_PROGRESS_TASK_DETAIL = 'INSERT_PROGRESS_TASK_DETAIL'
const UPDATE_PROGRESS_TASK_DETAIL = 'UPDATE_PROGRESS_TASK_DETAIL'
const DELETE_PROGRESS_TASK_DETAIL = 'DELETE_PROGRESS_TASK_DETAIL'
const GET_PROGRESS_DETAIL_BY_HEADER_CODE = 'GET_PROGRESS_DETAIL_BY_HEADER_CODE'
const UPDATE_TASK_HEADER_SORT_NO = 'UPDATE_TASK_HEADER_SORT_NO'

const GET_ALL_LOCATION =  'GET_ALL_LOCATIONS'
const GET_ALL_MASTER_EQUIPMENT = 'GET_ALL_MASTER_EQUIPMENT'
const GET_EQUIPMENT_LABOUR_BY_SR_CODE = 'GET_EQUIPMENT_LABOUR_BY_SR_CODE'

const INSERT_PROGRESS_DELAY = 'INSERT_PROGRESS_DELAY'
const UPDATE_PROGRESS_DELAY = 'UPDATE_PROGRESS_DELAY'
const DELETE_PROGRESS_DELAY = 'DELETE_PROGRESS_DELAY'
const GET_PROGRESS_DELAY_BY_TASK_HEADER_CODE = 'GET_PROGRESS_DELAY_BY_TASK_HEADER_CODE'
const GET_PROGRESS_ACTIVITY_TASK_HEADER_DELAY = 'GET_PROGRESS_ACTIVITY_TASK_HEADER_DELAY'
const GET_MASTER_DELAY_TYPE = 'GET_MASTER_DELAY_TYPE'
const GET_MASTER_DELAY = 'GET_MASTER_DELAY'

const EXPORT_EXCEL_CARGO = 'EXPORT_EXCEL_CARGO'
const IMPORT_EXCEL_CARGO = 'IMPORT_EXCEL_CARGO'

const GET_ALL_MASTER_DATA = 'GET_ALL_MASTER_DATA'

const GET_ALL_CCU       = 'GET_ALL_CCU'
const GET_ALL_CCU_UNIT  = 'GET_ALL_CCU_UNIT'
const GET_ALL_CCU_HIRED = 'GET_ALL_CCU_HIRED'
const INSERT_CCU        = 'INSERT_CCU'
const UPDATE_CCU        = 'UPDATE_CCU'
const DELETE_CCU        = 'DELETE_CCU'

const GET_ALL_WORK_PERMIT = 'GET_ALL_WORK_PERMIT'
const UPSERT_WORK_PERMIT  = 'UPSERT_WORK_PERMIT'

const GET_ALL_CONTRACT_TITLES = 'GET_ALL'

const GET_ALL_CONTRACT = 'GET_ALL'
const SORT_AUTO_UPDATE_TASK_HEADER ='SORT_AUTO_UPDATE_TASK_HEADER'

export default {
  INSERT_CCU,
  UPDATE_CCU,
  DELETE_CCU,
  GET_ALL_CCU,
  GET_ALL_CONTRACT,
  GET_ALL_CCU_UNIT,
  GET_MASTER_DELAY,
  GET_ALL_LOCATION,
  GET_ALL_CCU_HIRED,
  UPSERT_WORK_PERMIT,
  EXPORT_EXCEL_CARGO,
  IMPORT_EXCEL_CARGO,
  GET_ALL_WORK_PERMIT,
  GET_ALL_MASTER_DATA,
  INSERT_PROGRESS_DELAY,
  UPDATE_PROGRESS_DELAY,
  DELETE_PROGRESS_DELAY,
  GET_MASTER_DELAY_TYPE,
  GET_ALL_CONTRACT_TITLES,
  UPDATE_PROGRESS_ACTIVITY,
  GET_ALL_MASTER_EQUIPMENT,
  UPDATE_TASK_HEADER_SORT_NO,
  UPDATE_ACTIVITY_SETTLEMENT,
  INSERT_PROGRESS_TASK_HEADER,
  UPDATE_PROGRESS_TASK_HEADER,
  DELETE_PROGRESS_TASK_HEADER,
  INSERT_PROGRESS_TASK_DETAIL,
  UPDATE_PROGRESS_TASK_DETAIL,
  DELETE_PROGRESS_TASK_DETAIL,
  SORT_AUTO_UPDATE_TASK_HEADER,
  HANDLE_CHECK_PROGRESS_ACTIVITY,
  UPDATE_ACTIVITY_AND_TASK_HEADER,
  GET_EQUIPMENT_LABOUR_BY_SR_CODE,
  GET_PROGRESS_DETAIL_BY_HEADER_CODE,
  GET_PROGRESS_DELAY_BY_TASK_HEADER_CODE,
  GET_PROGRESS_ACTIVITY_TASK_HEADER_DELAY,
  GET_PROGRESS_ACTIVITY_BY_SR_CODE_COMPLETE_DATA,
  UPDATE_ACTIVITY_AND_TASK_HEADER_FOR_SETTLEMENT,
}