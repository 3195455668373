import axios from 'axios'

import { Config, Header as HeaderPlain } from '../../config.api'

const Header = async () => {
  const header = await HeaderPlain()
  header.headers['by-pass'] = true
  return header
}

const GetHeaderButtonUrls = async (skip, take, filters) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/enums?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const GetAllPort = async () => {
  try {
    const response = await axios.get(`${Config.MAIN_SERVICE_ENDPOINT}/locations`, await Header())
    return response.data
  } catch (error) {
    return error
  }
}

const GetAllVessel = async () => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/maps/vessel_location`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const GetVesselDetailByCode = async (vesselCode) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/maps/vessel_summary/${vesselCode}`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const GetVesselDetailByString = async (type, value) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/maps/search_vessel/${type}/${value}`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const GetIntervalVessel = async (skip, take, filters) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/enums?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const GetAllMasterDataVessel = async (skip, take, filters) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/vessels?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const GetNotification = async () => {
  try {
    const response = await axios.get(`${Config.WORKFLOW_SERVICE_ENDPOINT}/notification/detail?filter=[]`, await Header())
    return response.data
  } catch (error) {
    return error
  }
}

const UpdateIsRead = async (data) => {
  try {
    const response = await axios.put(
      `${Config.WORKFLOW_SERVICE_ENDPOINT}/notification/read`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const CheckExpired = async () => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}`,
      HeaderPlain()
    )
    return response
  } catch (error) {
    return error
  }
}

const GetNotificationBell = async () => {
  try {
    const response = await axios.get(`${Config.WORKFLOW_SERVICE_ENDPOINT}/notification/bell`, await Header())
    return response.data
  } catch (error) {
    return error
  }
}

export default {
  GetAllPort,
  GetAllVessel,
  UpdateIsRead,
  CheckExpired,
  GetNotification,
  GetIntervalVessel,
  GetNotificationBell,
  GetHeaderButtonUrls,
  GetVesselDetailByCode,
  GetAllMasterDataVessel,
  GetVesselDetailByString,
}
