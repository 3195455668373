import { SET_SERVICE_WORKER_REGISTRATION } from '../../actions'
const initialState = {
  isRegistered: false,
}

const serviceWorkerReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
  case SET_SERVICE_WORKER_REGISTRATION: {
    return { ...action.payload }
  }
  default: {
    return state
  }
  }
}

export default serviceWorkerReducer
