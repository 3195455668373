import { PublicClientApplication } from '@azure/msal-browser'
import { AuthConfig } from './authconfig'
import axios from 'axios'
import jwt from 'jsonwebtoken'
import { UserAPI } from '../api'
import { store } from '../redux/store'
import { SET_USER_LOGIN, SET_USER_PROFILE, SET_USER_ROLE_MAPPING } from '../redux/actions'
import getConfig from 'next/config'
import masterDataApi from '../api/master-data/master-data.api'

const USER_TYPE = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL'
}

const { publicRuntimeConfig } = getConfig()

const APP_ID = process.env.APP_ID || publicRuntimeConfig.APP_ID
const WEB_PATH = process.env.WEB_PATH || publicRuntimeConfig.WEB_PATH
const LINTAS_API = process.env.LINTAS_API || publicRuntimeConfig.LINTAS_API
const LINTAS_URL = process.env.LINTAS_PATH || publicRuntimeConfig.LINTAS_PATH
const REDIRECT_URI = process.env.REDIRECT_URI || publicRuntimeConfig.REDIRECT_URI
const AUTHENTICATION_URL = process.env.AUTHENTICATION_URL || publicRuntimeConfig.AUTHENTICATION_URL

class AuthService {
  pcaClient;
  b2cClient;
  loginRequest;
  account;

  constructor() {
    let config = new AuthConfig()
    this.pcaClient = new PublicClientApplication(config.pcaConfig)
    this.b2cClient = new PublicClientApplication(config.b2cConfig)

    this.loginRequest = {
      scopes: ['openid', 'profile'],
      redirectUri: REDIRECT_URI,
    }
    this.account = null
  }

  loadAuthModule() {
    this.pcaClient
      .handleRedirectPromise()
      .then((resp) => {
        if (resp) {
          this.handleResponsePCA(resp)
        }
      })
      .catch(console.error)

    this.b2cClient
      .handleRedirectPromise()
      .then((resp) => {
        if (resp) {
          this.handleResponseB2C(resp)
        }
      })
      .catch(console.error)
  }

  async handleResponsePCA(response) {
    if (response !== null) {
      localStorage.setItem('userType', USER_TYPE.INTERNAL)
      let userinfo = await this.getUserInfo(response)
      let finalToken = {
        User: userinfo.data.message.user,
        Name: userinfo.data.message.user.Name,
        UserProfile: userinfo.data.message.user,
        UserLocations: userinfo.data.message.locations,
      }
      localStorage.setItem('token', response.idToken)
      const  responseLocation = await masterDataApi.GetMasterDataLocation({}, {})
      if (responseLocation && responseLocation.data && responseLocation.data.message &&  responseLocation.data.message.data) {
        localStorage.setItem('list_locations',   JSON.stringify(responseLocation.data.message.data))
      }

      localStorage.setItem('user_info', JSON.stringify(finalToken))
      const userProfileRequest = await UserAPI.getUser(userinfo.data.message.user.ID)
      const userProfileData = userProfileRequest ? userProfileRequest.data : ''
      localStorage.setItem('user_profile', JSON.stringify(userProfileData.data))
      const location =
      userinfo.data.message.user &&
      userinfo.data.message.locations &&
      userinfo.data.message.locations.length === 1
        ? userinfo.data.message.locations[0]
        : null

      const userProfile = {
        ...userProfileData.data,
        Location: location,
      }
      store.dispatch({
        type: SET_USER_PROFILE,
        payload: JSON.stringify(userProfile),
      })

      store.dispatch({
        type: SET_USER_LOGIN,
        payload: JSON.stringify(finalToken),
      })

      const access = userinfo.data.message.roles.filter((rl) => rl.AllowView).map((role) => {
        return {
          id: role.ModuleID,
          path: role.ModulePath,
          allowEdit: role.AllowEdit,
          allowView: role.AllowView,
          allowPrint: role.AllowPrint,
          name: role.ModuleDescription,
          parentId: role.ModuleParentID,
          allowCreate: role.AllowCreate,
          allowDelete: role.AllowDelete,
          allowUpload: role.AllowUpload,
          allowDownload: role.AllowDownload,
          activeName: role.ModulePath.replace('/', ''),
        }
      })
      store.dispatch({
        type: SET_USER_ROLE_MAPPING,
        payload: access,
      })
      let roleMapping = await this.getRoleMapping(response, userinfo.data.message.user.RoleID)
      roleMapping = roleMapping.data.RoleMappings.filter((rl) => rl.AllowView)
      localStorage.setItem('role_mapping', JSON.stringify(roleMapping))
      window.location.href = WEB_PATH ? localStorage.url ? localStorage.url : WEB_PATH + '/dashboard' : '/dashboard'
    }else{
      console.log('test')
    }
  }

  async handleResponseB2C(response) {
    if (response !== null) {
      let temp = response.idTokenClaims
      let accountTemp = { ...response.account, username: temp.email }
      localStorage.setItem('userType', USER_TYPE.EXTERNAL)
      let userinfo = await this.getUserInfo({ ...response, account: accountTemp })
      let finalToken = {
        User: userinfo.data.message.user,
        Name: userinfo.data.message.user.Name,
        UserProfile: userinfo.data.message.user,
        UserLocations: userinfo.data.message.locations,
      }
      localStorage.setItem('token', response.idToken)
      const  responseLocation = await masterDataApi.GetMasterDataLocation({}, {})
      if (responseLocation && responseLocation.data && responseLocation.data.message &&  responseLocation.data.message.data) {
        localStorage.setItem('list_locations',   JSON.stringify(responseLocation.data.message.data))
      }
      localStorage.setItem('user_info', JSON.stringify(finalToken))
      const userProfileRequest = await UserAPI.getUser(userinfo.data.message.user.ID)
      const userProfileData = userProfileRequest ? userProfileRequest.data : ''
      localStorage.setItem('user_profile', JSON.stringify(userProfileData.data))
      const location =
      userinfo.data.message.user &&
      userinfo.data.message.locations &&
      userinfo.data.message.locations.length === 1
        ? userinfo.data.message.locations[0]
        : null

      const userProfile = {
        ...userProfileData.data,
        Location: location,
      }
      store.dispatch({
        type: SET_USER_PROFILE,
        payload: JSON.stringify(userProfile),
      })

      store.dispatch({
        type: SET_USER_LOGIN,
        payload: JSON.stringify(finalToken),
      })

      const access = userinfo.data.message.roles.filter((rl) => rl.AllowView).map((role) => {
        return {
          id: role.ModuleID,
          path: role.ModulePath,
          allowEdit: role.AllowEdit,
          allowView: role.AllowView,
          allowPrint: role.AllowPrint,
          name: role.ModuleDescription,
          parentId: role.ModuleParentID,
          allowCreate: role.AllowCreate,
          allowDelete: role.AllowDelete,
          allowUpload: role.AllowUpload,
          allowDownload: role.AllowDownload,
          activeName: role.ModulePath.replace('/', ''),
        }
      })
      store.dispatch({
        type: SET_USER_ROLE_MAPPING,
        payload: access,
      })
      let roleMapping = await this.getRoleMapping({ ...response, account: accountTemp }, userinfo.data.message.user.RoleID)
      roleMapping = roleMapping.data.RoleMappings.filter((rl) => rl.AllowView)
      localStorage.setItem('role_mapping', JSON.stringify(roleMapping))
      window.location.href = WEB_PATH ? localStorage.url ? localStorage.url : WEB_PATH + '/dashboard' : '/dashboard'
    }else{
      console.log('test')
    }
  }

  signInInternal(username) {
    this.pcaClient.loginRedirect({ ...this.loginRequest, loginHint: username })
  }

  signInB2C() {
    this.b2cClient.loginRedirect(this.loginRequest)
  }

  async signOut() {
    console.log(LINTAS_URL)
    let userType = localStorage.getItem('userType')
    if(userType === USER_TYPE.INTERNAL) {
      localStorage.clear()
      await this.pcaClient.logout({ postLogoutRedirectUri: LINTAS_URL + 'login' })
    }else{
      localStorage.clear()
      await this.b2cClient.logout({ postLogoutRedirectUri: LINTAS_URL })
    }
  }

  async getTokenSilent() {
    let userType = localStorage.getItem('userType')
    if(userType === USER_TYPE.INTERNAL) {
      let accounts = this.pcaClient.getAllAccounts()
      let token = await this.pcaClient.acquireTokenSilent({
        ...this.loginRequest,
        forceRefresh: true,
        account: accounts[0] })
      return token
    }else if(userType === USER_TYPE.EXTERNAL) {
      let accounts = this.b2cClient.getAllAccounts()
      let token = await this.b2cClient.acquireTokenSilent({ ...this.loginRequest, account: accounts[0] })

      return token
    }else{
      return undefined
    }
  }

  async getUserInfo(authResult) {
    const headers = {
      'Token-Auth': authResult.idToken,
    }
    let body = {
      Action: 'READ_USER',
      User: {
        Email: authResult.account?.username,
        sub: authResult.uniqueId
      },
      ApplicationCode: 'PORT',
    }

    let result = await axios.post(AUTHENTICATION_URL + 'authentication/userinfo', body, { headers: headers })

    return result
  }

  async getRoleMapping(authResult, roleID) {
    const headers = {
      'Token-Auth': authResult.idToken,
    }
    let body = {
      Action: 'READ_ROLE_MAPPING_BY_ROLE_ID_APP_ID',
      RoleMapping: {
        ApplicationID: parseInt(APP_ID),
        RoleID: roleID
      },
    }

    let result = await axios.post(LINTAS_API + '/role-management/role-mapping', body, {
      headers: headers,
    })

    return result
  }

  async getTokenInfo(token) {
    const decoded = jwt.decode(token, { complete: true })
    return decoded
  }
}


const authService = new AuthService()

export default authService