import Axios from 'axios'
import { Actions } from '../../utils'
import { Config, Header } from '../config.api'

const GetAllSettlement = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/settlements?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetSettlementByParam = async (srNo) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/settlements?skip=0&take=100&filter=[{ "field": "sr_no", "type": "text", "value": "${srNo}" }]`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetDataDelay = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/progress_delays?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetDataActivities = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/progress_activities?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetSRCategory = async () => {
  try {
    const response = await Axios.get(`${Config.MAIN_SERVICE_ENDPOINT}/sr_category`, await Header())
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAllMasterProducts = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/products?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAllSrProducts = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/sr_products?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAllAttachment = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/attachments_settlement?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAllFromTableEnum = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/enums?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetUomTypes = async () => {
  try {
    const response = await Axios.get(`${Config.MAIN_SERVICE_ENDPOINT}/uomtype`, await Header())
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const InsertProduct = async (data) => {
  try {
    const response = await Axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/sr_product/insert`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const UpdateProduct = async (data) => {
  try {
    const response = await Axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/sr_product/update`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const DeleteProduct = async (id) => {
  try {
    const response = await Axios.delete(
      `${Config.MAIN_SERVICE_ENDPOINT}/sr_product/delete/${id}`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const InsertDocument = async (data) => {
  try {
    const response = await Axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/attachment/insert`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

// const UpdateDocument = async (data) => {
//   try {
//     const response = await Axios.put(
//       `${Config.MAIN_SERVICE_ENDPOINT}/attachment/update`,
//       data,
//       await Header()
//     )
//     return response.data
//   } catch (error) {
//     return error
//   }
// }

const DeleteDocument = async (id) => {
  try {
    const response = await Axios.delete(
      `${Config.MAIN_SERVICE_ENDPOINT}/attachment/delete/${id}`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const GetAttachmentFile = async (filename) => {
  try {
    const response = await Axios.get(
      `${Config.ATTACHMENT_SERVICE_ENDPOINT}/uploader/download?filename=${encodeURIComponent(filename)}`
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAttachmentFileSR = async (filename) => {
  try {
    const response = await Axios.get(
      `${Config.ATTACHMENT_SERVICE_ENDPOINT}/uploader/download-sr?filename=${encodeURIComponent(filename)}`
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}
const UploadAttachments = async (data) => {
  return Axios.post(`${Config.ATTACHMENT_SERVICE_ENDPOINT}/uploader/multiple`, data, await Header())
}

const InsertAlldoc = async (data) => {
  try {
    const response = await Axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/alldoc/insert`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetColumnOption = async () => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/column_option/v_t_settlements`
    const response = await Axios.get(url, await Header())
    return response
  } catch (error) {
    console.log(error)
  }
}

const UpdateColumnOption = async (columns) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/column_option`
    const response = await Axios.post(url, columns, await Header())
    return response
  } catch (error) {
    console.log(error)
  }
}

const GetNotification = async (filters) => {
  try {
    const response = await Axios.get(
      `${Config.WORKFLOW_SERVICE_ENDPOINT}/notifications?filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const SubmitSettlement = async (data) => {
  try {
    const response = await Axios.put(
      `${Config.WORKFLOW_SERVICE_ENDPOINT}/approval/submitSettlementStatus`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const ConfirmSettlement = async (data) => {
  try {
    const response = await Axios.put(
      `${Config.WORKFLOW_SERVICE_ENDPOINT}/approval/confirmSettlementStatus`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetHistoryProgress = async (srNo) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/progress_status?skip=0&take=100&filter=[{ "field": "sr_no", "type": "text", "value": "${srNo}" }]`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetCargoTotalData = async (Code) => {
  try {
    const response = await Axios.get(`${Config.MAIN_SERVICE_ENDPOINT}/cargo_total/${Code}`, await Header())
    return response
  } catch (error) {
    console.log('error', error)
  }
}

const GetPrintData = async (Code) => {
  try {
    const response = await Axios.get(`${Config.MAIN_SERVICE_ENDPOINT}/settlement_print/${Code}`, await Header())
    return response
  } catch (error) {
    console.log('error', error)
  }
}

const GetPrintApprovalData = async (Code) => {
  try {
    const response = await Axios.get(`${Config.MAIN_SERVICE_ENDPOINT}/settlement_print_approval/${Code}`, await Header())
    return response
  } catch (error) {
    console.log('error', error)
  }
}

const GetRoleMapping = async () => {
  try {
    const response = await Axios.get(`${Config.WORKFLOW_SERVICE_ENDPOINT}/approval/roleMapping`, await Header())
    return response
  } catch (error) {
    console.log('error', error)
  }
}

const InboundToPBMS = async (srCode, msrStatus) => {
  try {
    const urlMsr = `${Config.MAIN_SERVICE_ENDPOINT}/inbound_pbms_msr/${srCode}`
    const urlWo = `${Config.MAIN_SERVICE_ENDPOINT}/inbound_pbms_wo/${srCode}`
    if (msrStatus === '' || msrStatus.toLowerCase() === 'failed') {
      const result = await Axios.get(urlMsr, await Header())
      await Axios.get(urlWo, await Header())
      return result
    } else {
      const result = await Axios.get(urlWo, await Header())
      return result
    }
  } catch (error) {
    console.log('InboundToPBMS err:', error)
    return error
  }
}

const UpdateBillingBerthDur = async (data) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/settlement/billing_berth_duration`
    const response = await Axios.put(url, data, await Header())
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetInboundStatus = async (code) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/inbound_pbms/settlement/${code}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const updateActivityAndTaskHeaderForSettlement = async (activityPayload, taskHeaderPayload) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-activity` // eslint-disable-line
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.UPDATE_ACTIVITY_AND_TASK_HEADER_FOR_SETTLEMENT,
      activity_payload: {
        ...activityPayload,
        modified_by: requestHeader.headers.Email,
      },
      task_header_payload: {
        ...taskHeaderPayload,
        modified_by: requestHeader.headers.Email,
      },
    }

    return await Axios.post(url, requestPayload, requestHeader)
  } catch(error) {
    throw new Error('updateActivityAndTaskHeaderForSettlement is not OK => ' +  error.toString())
  }
}

const updateActivitySettlement = async (activityPayload) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/new/progress-activity` // eslint-disable-line
    const requestHeader = await Header()
    const requestPayload = {
      action: Actions.UPDATE_ACTIVITY_SETTLEMENT,
      activity_payload: {
        ...activityPayload,
        modified_by: requestHeader.headers.Email,
      },
    }

    return await Axios.post(url, requestPayload, requestHeader)
  } catch(error) {
    throw new Error('updateActivitySettlement is not OK => ' +  error.toString())
  }
}

// Will be deleted in future
import cargoData from './dummy-data-cargo.json'

const GetCargoData = async () => {
  return cargoData
}

// Will be deleted in future
import equipmentData from './dummy-data-equipment.json'

const GetEquipmentData = async () => {
  return equipmentData
}

export default {
  GetUomTypes,
  GetDataDelay,
  InsertAlldoc,
  GetPrintData,
  GetCargoData,
  GetCargoTotalData,
  GetSRCategory,
  InsertProduct,
  UpdateProduct,
  DeleteProduct,
  InboundToPBMS,
  InsertDocument,
  // UpdateDocument,
  DeleteDocument,
  GetRoleMapping,
  GetColumnOption,
  GetNotification,
  GetEquipmentData,
  GetInboundStatus,
  GetAllSrProducts,
  GetAllAttachment,
  GetAllSettlement,
  SubmitSettlement,
  ConfirmSettlement,
  GetDataActivities,
  GetAttachmentFile,
  UploadAttachments,
  UpdateColumnOption,
  GetHistoryProgress,
  GetAllFromTableEnum,
  GetAttachmentFileSR,
  GetPrintApprovalData,
  GetSettlementByParam,
  GetAllMasterProducts,
  UpdateBillingBerthDur,
  updateActivitySettlement,
  updateActivityAndTaskHeaderForSettlement,
}
