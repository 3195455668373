import { SET_SERVICE_REQUEST_DETAIL } from '../../actions'

const initialState = { dataSr: {} }

const serviceRequestDetailReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
  case SET_SERVICE_REQUEST_DETAIL: {
    return { ...state, dataSr: { ...action.payload } }
  }
  default: {
    return state
  }
  }
}

export default serviceRequestDetailReducer
