import { SET_USER_LOGIN, SET_USER_ROLE_MAPPING, SET_USER_TIMEOUT } from '../../actions'

const initialState = {
  Name: '',
  User: {},
  UserProfile: {},
  UserLocations: [],
}

const usersLoginReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
  case SET_USER_LOGIN: {
    let dataUser = JSON.parse(action.payload)

    if (action.payload && action.payload.length > 0) {
      dataUser = JSON.parse(action.payload)
    } else {
      const userInfo = JSON.parse(localStorage.getItem('user_info'))
      dataUser = {
        ...userInfo,
      }
    }

    return { ...state, ...dataUser }
  }
  case SET_USER_ROLE_MAPPING: {
    let roleMapping = action.payload

    if (action.payload && action.payload.length > 0) {
      roleMapping = action.payload
    } else {
      const role = JSON.parse(localStorage.getItem('role_mapping')).RoleMappings
      roleMapping = {
        ...role,
      }
    }

    return { ...state, roleMapping: roleMapping }
  }
  case SET_USER_TIMEOUT: {
    return { ...state, userTimeout: new Date() }
  }
  default: {
    return state
  }
  }
}

export default usersLoginReducer
