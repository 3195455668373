import { GET_CATEGORIES, GET_ACTIVITY_LIST, GET_ACTIVITY_DETAIL } from './activity'
import { GET_DATA_VESSEL, GET_VESSEL_SIZE, GET_VESSEL_CATEGORY, GET_VESSEL_TYPE, GET_DETAIL_VESSEL, GET_ATTACHMENT_FILE } from './vessel'

//------------------Master Data Vessel---------------------------
export {
  GET_CATEGORIES,
  GET_DATA_VESSEL,
  GET_VESSEL_TYPE,
  GET_VESSEL_SIZE,
  GET_DETAIL_VESSEL,
  GET_ACTIVITY_LIST,
  GET_VESSEL_CATEGORY,
  GET_ACTIVITY_DETAIL,
  GET_ATTACHMENT_FILE,
}
