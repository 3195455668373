import { combineReducers } from 'redux'

import appReducer from './app'
import mapsReducer from './maps'
import serviceWorkerReducer from './service-worker'
import { usersLoginReducer, usersProfileReducer } from './user'
import { activityReducer, vesselReducer } from './master-data'
import {
  executionViewType,
  executionDateReducer,
  executionDataStateReducer,
  executionModalConfirmReducer,
  executionValidateSRFormReducer
} from './execution'
import serviceRequestDetailReducer from './service-request-detail'
import serviceRequestAllReducer from './service-request-all'
import reportReducer from './report'

export default combineReducers({
  appReducer,
  mapsReducer,
  reportReducer,
  serviceWorkerReducer,
  usersLoginReducer,
  usersProfileReducer,
  vesselReducer,
  activityReducer,
  executionViewType,
  executionDateReducer,
  executionDataStateReducer,
  executionModalConfirmReducer,
  serviceRequestDetailReducer,
  serviceRequestAllReducer,
  executionValidateSRFormReducer
})
