import axios from 'axios'
import { Config, Header } from '../../config.api'

const GetListDataLog = async () => {
  try {
    const response = await axios.get(
      `${Config.LOG_SERVICE_ENDPOINT}/audit_trail`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const PostDataLog = async (data) => {
  try {
    const response = await axios.post(
        `${Config.LOG_SERVICE_ENDPOINT}/audit_trail/insert`,
        data,
        await Header()
    )
    return response
  } catch (error) {
    return error
  }
}


export default {
  PostDataLog,
  GetListDataLog,
}