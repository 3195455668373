import moment from 'moment'
import momenttz from 'moment-timezone'
const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export const dateToShortMonth = (date) => {
  return date.toLocaleString('default', { month: 'short' })
}

export const printDate = (date) => {
  let newDate = new Date(date)
  return newDate.getDate() > 9 ? newDate.getDate() : `0${newDate.getDate()}`
}
export const printDay = (date) => {
  let newDate = new Date(date)
  return `${days[newDate.getDay()]}`
}

export const datePlusOneWeek = (date) => {
  let newDate = new Date(date)
  let days = 6
  return newDate.setTime(newDate.getTime() + days * 24 * 60 * 60 * 1000)
}

export const datePlusOneMonth = (date) => {
  let newDate = new Date(date)
  let days = 29
  return newDate.setTime(newDate.getTime() + days * 24 * 60 * 60 * 1000)
}

export const dateToString = (date) => {
  let newDate = new Date(date)
  let dateHandle =
    newDate.getDate() > 9 ? newDate.getDate() : `0${newDate.getDate()}`

  return (
    dateHandle + ' ' + dateToShortMonth(newDate) + ' ' + newDate.getFullYear()
  )
}

export const getDurationBetweenTwoTime = (
  startTime,
  finishTime,
  breakTime = 0
) => {
  const dlStart = moment(moment(startTime).format('YYYY-MM-DD HH:mm'))
    .second(0)
    .milliseconds(0)
  const dlFinish = moment(moment(finishTime).format('YYYY-MM-DD HH:mm'))
    .second(0)
    .milliseconds(0)
  let durationDiff =
    moment(dlFinish).diff(moment(dlStart), 'minutes') - breakTime
  durationDiff =
    isNaN(durationDiff) || durationDiff < 0 || durationDiff == 0
      ? 0
      : durationDiff
  return durationDiff
}

const getListLocations = () => {
  const list_locations = JSON.parse(localStorage.getItem('list_locations'))
  return {
    list_locations_wib: list_locations
      .filter((e) => e.timezone === 'WIB')
      .map((e) => e.description.toLowerCase()),
    list_locations_wita: list_locations
      .filter((e) => e.timezone === 'WITA')
      .map((e) => e.description.toLowerCase()),
    list_locations_wit: list_locations
      .filter((e) => e.timezone === 'WIT')
      .map((e) => e.description.toLowerCase())
  }
}
export const ConvertLocalTimeGet = (location, value, format) => {
  let result
  if (
    value &&
    moment(value)._isValid &&
    location != undefined &&
    new Date(value).getFullYear() > 2000
  ) {
    const date = new Date(value).toISOString()
    const { list_locations_wib, list_locations_wita, list_locations_wit } =
      getListLocations()
    if (list_locations_wib.includes(location.toLowerCase())) {
      result = momenttz(date).tz('Asia/Jakarta').format(format)
    } else if (list_locations_wita.includes(location.toLowerCase())) {
      result = momenttz(date).tz('Asia/Makassar').format(format)
    } else if (list_locations_wit.includes(location.toLowerCase())) {
      result = momenttz(date).tz('Asia/Jayapura').format(format)
    }
  }

  return result
}

export const ConvertLocalTimePost = (location, date) => {
  const localTime = new Date()
  let additionalHours = 1
  const { list_locations_wib, list_locations_wita, list_locations_wit } =
    getListLocations()

  if (list_locations_wib.includes(location.toLowerCase())) {
    additionalHours = (localTime.getTimezoneOffset() + 420) / 60
  } else if (list_locations_wita.includes(location.toLowerCase())) {
    additionalHours = (localTime.getTimezoneOffset() + 480) / 60
  } else if (list_locations_wit.includes(location.toLowerCase())) {
    additionalHours = (localTime.getTimezoneOffset() + 540) / 60
  }

  const result = moment(date)
    .add(additionalHours * -1, 'hours')
    .toISOString()
  return result
}

export const ConvertLocalTimePostUTC = (date) => {
  var now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )

  const result = new Date(now_utc).toISOString()
  return result
}

export const GetDurationMinutes = (startDate, finishDate, breakTime) => {
  /*const endTime = new Date(finishDate)
  const startTime = new Date(startDate)
  */
  const startTime = moment(moment(startDate).format('YYYY-MM-DD HH:mm'))
    .second(0)
    .milliseconds(0)
  const endTime = moment(moment(finishDate).format('YYYY-MM-DD HH:mm'))
    .second(0)
    .milliseconds(0)
  //  1 : (1000 * 60) convert milisecond to minutes
  const diff = ((endTime - startTime) / 60000).toFixed(0)

  const timeAfterBreak = diff - breakTime
  const duration = parseInt(timeAfterBreak)
    ? `${Math.floor(timeAfterBreak / 60)} Hours ${timeAfterBreak % 60} Minutes`
    : '0 Hours 0 Minutes'

  return duration
}

export const GetDurationInMinutes = (startDate, finishDate, breakTime) => {
  const endTime = moment(new Date(finishDate)).format('DD MMMM YYYY HH:mm')
  const startTime = moment(new Date(startDate)).format('DD MMMM YYYY HH:mm')

  const diff = moment.duration(moment(endTime).diff(startTime))
  const duration = diff.asMinutes() - breakTime

  return duration
}

export const GetDurationHours = (startDate, finishDate, breakTime) => {
  const endTime = moment(new Date(finishDate)).format('DD MMMM YYYY HH:mm')
  const startTime = moment(new Date(startDate)).format('DD MMMM YYYY HH:mm')

  const diff = moment.duration(moment(endTime).diff(startTime))
  const cut = breakTime / 60
  const duration = diff.asHours() - cut

  return duration
}

export const GetDurationDays = (startDate, finishDate, breakTime) => {
  const endTime = moment(new Date(finishDate)).format('DD MMMM YYYY HH:mm')
  const startTime = moment(new Date(startDate)).format('DD MMMM YYYY HH:mm')

  const diff = moment.duration(moment(endTime).diff(startTime))
  const __hours = diff.asHours() - breakTime
  const __days = __hours <= 0 ? 0 : (__hours / 24).toFixed(1)

  const duration = `${__days} days`
  return duration
}

export const TimeConvertToHour = (startDate, finishDate) => {
  const endTime = moment(new Date(finishDate), 'HH:mm')
  const startTime = moment(new Date(startDate), 'HH:mm')

  var dif = moment.duration(endTime.diff(startTime))

  const timeAfterDiff =
    dif.months() * 30 * 24 * 60 +
    dif.days() * 24 * 60 +
    dif.hours() * 60 +
    dif.minutes() -
    0

  const duration = parseInt(timeAfterDiff)
    ? `${Math.floor(timeAfterDiff / 60)} Hours ${timeAfterDiff % 60} Minutes`
    : '0 Hours 0 Minutes'

  return duration
}

export const durationToFormattedString = (duration) => {
  return parseInt(duration)
    ? `${Math.floor(duration / 60)} Hours ${duration % 60} Minutes`
    : '0 Hours 0 Minutes'
}