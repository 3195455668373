import axios from 'axios'

import { Config } from '../config.api'

const GetRoleMapping = async (roleId, token) => {
  try {
    const response = await axios.post(
      Config.ROLE_MANAGEMENT_ENDPOINT,
      {
        Action: 'READ_ROLE_MAPPING_BY_ROLE_ID_APP_ID',
        RoleMapping: {
          RoleID: roleId,
          ApplicationID: parseInt(Config.APPLICATION_ID),
        },
      },
      {
        headers: {
          'token-auth': token,
        },
      }
    )
    return response.data
  } catch (error) {
    return { error }
  }
}

export default { GetRoleMapping }
