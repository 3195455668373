import { LogLevel } from '@azure/msal-browser'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const CLIENT_ID = process.env.CLIENT_ID || publicRuntimeConfig.CLIENT_ID
const CLIENT_ID_B2C = process.env.CLIENT_ID_B2C || publicRuntimeConfig.CLIENT_ID_B2C
const REDIRECT_URI = process.env.REDIRECT_URI || publicRuntimeConfig.REDIRECT_URI
const SIGN_IN_URL = process.env.SIGN_IN_URL || publicRuntimeConfig.SIGN_IN_URL
const SIGN_IN_URL_B2C = process.env.SIGN_IN_URL_B2C || publicRuntimeConfig.SIGN_IN_URL_B2C
const LINTAS_URL = process.env.LINTAS_PATH || publicRuntimeConfig.LINTAS_PATH

const knownAuthorities = [
  SIGN_IN_URL,
  SIGN_IN_URL_B2C
]

export class AuthConfig {
  pcaConfig;
  b2cConfig;

  constructor() {
    this.pcaConfig = {
      auth: {
        clientId: CLIENT_ID,
        authority: SIGN_IN_URL,
        redirectUri: REDIRECT_URI,
        knownAuthorities: knownAuthorities,
        navigateToLoginRequestUrl: false,
        postLogoutRedirectUri: LINTAS_URL + 'login',
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
      system: {
        loggerOptions: {
          loggerCallback: this.handleCallback
        },
      },
    }

    this.b2cConfig = {
      auth: {
        clientId: CLIENT_ID_B2C,
        authority: SIGN_IN_URL_B2C,
        redirectUri: REDIRECT_URI,
        knownAuthorities: knownAuthorities,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
      system: {
        loggerOptions: {
          loggerCallback: this.handleCallback
        },
      },
    }
  }

  handleCallback = (level, message, containsPii) => {
    if (containsPii) {
      return
    }
    switch (level) {
    case LogLevel.Error:
      console.log('LOG LEVEL ERROR ', message)
      return
    case LogLevel.Info:
      // console.log('LOG LEVEL INFO ', message)
      return
    case LogLevel.Verbose:
      // console.log('LOG LEVEL VERBOSE ', message)
      return
    case LogLevel.Warning:
      // console.log('LOG LEVEL WARNING ', message)
      return
    }
  }
}