import { SET_VIEW_MAP, UPDATE_VIEW_MAP } from '../../actions'
const initialState = {
  width: '100vw',
  height: 'calc(88.72vh)',
  latitude: 0,
  longitude: 0,
  zoom: 5,
}

const mapsReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
  case SET_VIEW_MAP: {
    return { ...action.payload }
  }
  case UPDATE_VIEW_MAP: {
    return { ...state, ...action.payload }
  }
  default: {
    return state
  }
  }
}

export default mapsReducer
