import {
  GET_DATA_VESSEL,
  GET_VESSEL_CATEGORY,
  GET_VESSEL_TYPE,
  GET_VESSEL_SIZE,
  GET_DETAIL_VESSEL,
  GET_ATTACHMENT_FILE,
} from '../../../actions'

const initialState = {
  count: 0,
  dataVessel: [],
  dataDetail: [],
  dataVesselSize: [],
  dataVesselCategory: [],
  dataVesselType: [],
  dataAttachment: [],
}

const vesselReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
  case GET_DATA_VESSEL: {
    return {
      ...state,
      dataVessel: action.payload.data,
      count: action.payload.dataCount,
    }
  }
  case GET_DETAIL_VESSEL: {
    return {
      ...state,
      dataDetail: action.payload,
    }
  }
  case GET_VESSEL_CATEGORY: {
    return {
      ...state,
      dataVesselCategory: action.payload,
    }
  }
  case GET_VESSEL_TYPE: {
    return {
      ...state,
      dataVesselType: action.payload,
    }
  }
  case GET_VESSEL_SIZE: {
    return {
      ...state,
      dataVesselSize: action.payload,
    }
  }
  case GET_ATTACHMENT_FILE: {
    return {
      ...state,
      dataAttachment: action.payload,
    }
  }
  default: {
    return state
  }
  }
}

export default vesselReducer
