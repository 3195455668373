import axios from 'axios'
import { Config, Header } from '../../config.api'

const GetListDataApproval = async (skip, take, filter) => {
  try {
    const response = await axios.get(
        `${Config.MAIN_SERVICE_ENDPOINT}/approvals?skip=${skip}&take=${take}&filter=${filter}`,
        await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const GetListDataLocation = async () => {
  try {
    const response = await axios.get(
        `${Config.MAIN_SERVICE_ENDPOINT}/locations`,
        await Header()
    )
    return response
  } catch (error) {
    return error
  }
}


const GetListDataType = async () => {
  try {
    const response = await axios.get(
        `${Config.MAIN_SERVICE_ENDPOINT}/roles`,
        await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const GetListDataEnum = async () => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/enums?take=5&skip=0&filter=[{"type": "text", "field": "type", "value": "Approval_Level"}]`,
      await Header()
    )
    return response
  }catch (error) {
    return error
  }
}

const PostDataApproval = async (formData) => {
  try {
    const response = await axios.post(
        `${Config.MAIN_SERVICE_ENDPOINT}/approval/insert`,
        formData,
        await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

export const EditDataApproval = async (formData) => {
  try {
    const response = await axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/approval/update`,
      formData,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

export const DelDataApproval = async (id) => {
  try {
    const response = await axios.delete(
        `${Config.MAIN_SERVICE_ENDPOINT}/approval/delete/${id}`,
        await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

export default {
  GetListDataEnum,
  GetListDataType,
  DelDataApproval,
  PostDataApproval,
  EditDataApproval,
  GetListDataApproval,
  GetListDataLocation
}

