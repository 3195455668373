import Axios from 'axios'
import { Config, Header } from '../config.api'

const GetAllReportColumns = async () => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/enums?skip=0&take=1000&filter=[{"type":"text","field":"type","value":"Report_Column"}]`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetPortLocations = async () => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/locations`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetClients = async () => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/clients?skip=0&take=100&filter=[{"field":"is_active","type":"bool","value":"true"}]`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAgents = async () => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/agents?skip=0&take=100&filter=[{"field":"is_active","type":"bool","value":"true"}]`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetVessels = async () => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/vessels?skip=0&take=100&filter=[{"field":"is_active","type":"bool","value":"true"}]`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetSRCategory = async () => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/sr_category`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetVesselTypes = async () => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/vessel_types`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetJetty = async () => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/jettys?skip=0&take=20&filter=[]`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}
const GetSRStatus = async () => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/enums?skip=0&take=1000&filter=[{"type":"text","field":"type","value":"SR_Status"}]`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAllReports = async (skip, take, filters, filterPeriod) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/reports?skip=${skip}&take=${take}&filter=${filters}&filter_period=${filterPeriod}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}


export default {
  GetJetty,
  GetAgents,
  GetVessels,
  GetClients,
  GetSRStatus,
  GetSRCategory,
  GetAllReports,
  GetVesselTypes,
  GetPortLocations,
  GetAllReportColumns,
}