export default {
  logo: require('./logo.png'),
  logo2: require('./logo_2.svg'),
  jetty: require('./jetty.svg'),
  check: require('./check.svg'),
  success: require('./done.svg'),
  remove: require('./remove.svg'),
  BoatRed: require('./boat.svg'),
  plus: require('./plusIcon.svg'),
  iconJetty: require('./jetty.svg'),
  posponed: require('./postponed.png'),
  confirm: require('./Group-6167.svg'),
  reject: require('./Group-4582.svg'),
  approve: require('./Group-4550.svg'),
  doneBlue: require('./done-blue.svg'),
  SortIcon: require('./SortIcon.svg'),
  portIcon: require('./icon-port.svg'),
  jettyLogo: require('./jettyLogo.svg'),
  reviseRed: require('./Revise-red.svg'),
  reviseLogo: require('./Group-4995.svg'),
  vesselImage: require('./vessel-image.svg'),
  vesselIconBlack: require('./icon-vessel-black.svg'),
  vesselIconRed: require('./icon-vessel-red.svg'),
  vesselIconGreen: require('./icon-vessel-green.svg'),
  vesselIconPurple: require('./icon-vessel-purple.svg'),
  vesselIconBlackPulse: require('./icon-vessel-black-pulse.svg'),
  vesselIconRedPulse: require('./icon-vessel-red-pulse.svg'),
  vesselIconGreenPulse: require('./icon-vessel-green-pulse.svg'),
  navbarModal: require('./HeaderModal.svg'),
  pointHereIcon: require('./ellipse-11.svg'),
  logoModal: require('./HeaderLogoModal.svg'),
  inboundbtn: require('./Inbound-btn.svg'),
  deleteIcon: require('./baseline-delete-24px.svg'),
  sortDirIcon: require('./sort-dir-icon.svg'),
  xIcon: require('./x-icon.svg'),
  currentLocationIcon: require('./currentLocation.svg'),
  filesIconDoc: require('./files icon-DOCX.svg'),
  filesIconXls: require('./files icon-XLS.svg'),
  filesIconPdf: require('./files icon-PDF.svg'),
  filesIconImg: require('./files icon-IMG.svg'),
  DraftGreen: require('./DraftGreen.svg'),
  DraftRed: require('./DraftRed.svg'),
  DraftGrey: require('./DraftGrey.svg'),
  WaitAppGreen: require('./WaitAppGreen.svg'),
  WaitAppRed: require('./WaitAppRed.svg'),
  WaitAppGrey: require('./WaitAppGrey.svg'),
  AppRejGreen: require('./AppRejGreen.svg'),
  AppRejRed: require('./AppRejRed.svg'),
  AppRejGrey: require('./AppRejGrey.svg'),
  ProgGreen: require('./ProgGreen.svg'),
  ProgRed: require('./ProgRed.svg'),
  ProgGrey: require('./ProgGrey.svg'),
  CalendarType: require('./calendartype.svg'),
  CompGreen: require('./CompGreen.svg'),
  CompRed: require('./CompRed.svg'),
  CompGrey: require('./CompGrey.svg'),
  FinalGreen: require('./FinalGreen.svg'),
  FinalRed: require('./FinalRed.svg'),
  FinalGrey: require('./FinalGrey.svg'),
  MapsJetty: require('./MapsJettyNew.png'),
  MapsJettySorong: require('./MapsJettySorongNew.png'),
  MapsJettyBackground: require('./maps-jetty-BG.png'),
  MapsJettyBackgroundSorong: require('./JettyBackground.png'),
  BackgroundLocation: require('./DashboardLocation.png'),
  LocationIcon: require('./location-icon.png'),
  VGeneral_Cargo: require('./GeneralL.png'),
  VGeneral_CargoExtraSmall: require('./GeneralXS.svg'),
  VGeneral_CargoSmall: require('./GeneralS.svg'),
  VGeneral_CargoMedium: require('./GeneralM.svg'),
  VGeneral_CargoLarge: require('./GeneralL.svg'),
  VGeneral_CargoExtraLarge: require('./GeneralXL.svg'),
  VBarge: require('./BargeL.png'),
  VBargeSmall: require('./BargeS.png'),
  VBargeMedium: require('./BargeM.png'),
  VBargeLarge: require('./BargeL.png'),
  VLCT: require('./LCTL.png'),
  VLCTSmall: require('./LCTS.svg'),
  VLCTMedium: require('./LCTM.svg'),
  VLCTLarge: require('./LCTL.svg'),
  VTanker: require('./TankerL.png'),
  VTankerSmall: require('./TankerS.svg'),
  VTankerMedium: require('./TankerM.svg'),
  VTankerLarge: require('./TankerL.svg'),
  VTSV: require('./TSVL.png'),
  VTSVSmall: require('./TSVS.svg'),
  VTSVMedium: require('./TSVM.svg'),
  VTSVLarge: require('./TSVL.svg'),
  VRig: require('./Rig.png'),
  VRigMirror: require('./RigMirror.png'),
  Print: require('./print.svg'),
  Beyond: require('./Beyond.png'),
  JettyInfo: require('./JettyInfo.png'),
  Expired: require('./Expired.svg'),
  company: require('./company.svg'),
  time: require('./time.svg'),
  schedule: require('./Icon_schedule.svg'),

  //cargo
  ImportIcon: require('./import.svg'),
  ExportIcon: require('./export.svg'),
  UploaderIcon: require('./uploader.svg'),
  XlsIcon: require('./xls.svg'),
  AddIcon: require('./add_icon.svg'),
  cargoIcon: require('./icon_cargo.svg'),
  dots: require('./dots.svg'),
}
