import Axios from 'axios'
import { Config, Header } from '../../config.api'

const GetAllMasterDataDelay = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/delays?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetDelayType = async () => {
  try {
    const response = await Axios.get(`${Config.MAIN_SERVICE_ENDPOINT}/delay_types`, await Header())
    return response.data
  } catch (error) {
    return error
  }
}

const InsertDelay = async (data) => {
  try {
    const response = await Axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/delay/insert`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const UpdateDelay = async (data) => {
  try {
    const response = await Axios.put(`${Config.MAIN_SERVICE_ENDPOINT}/delay/update`, data, await Header())
    return response.data
  } catch (error) {
    return error
  }
}

const DeleteDelay = async (id) => {
  try {
    const response = await Axios.delete(
      `${Config.MAIN_SERVICE_ENDPOINT}/delay/delete/${id}`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

export default { GetAllMasterDataDelay, GetDelayType, InsertDelay, UpdateDelay, DeleteDelay }
