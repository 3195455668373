import { MODAL_CONFIRMATION_EXECUTION, RESET_MODAL_CONFIRMATION_EXECUTION } from '../../actions'

const initialState = {
  show: false,
  type: '',
  message: '',
}

const executionModalConfirmReducer = (state = initialState, action) => {
  switch (action.type) {
  case MODAL_CONFIRMATION_EXECUTION:
    return action.payload
  case RESET_MODAL_CONFIRMATION_EXECUTION:
    return initialState
  default: {
    return state
  }
  }
}

export default executionModalConfirmReducer
