const SET_DATE_EXECUTION = 'SET_DATE_EXECUTION'
const ADD_DAY_DATE_EXECUTION = 'ADD_DAY_DATE_EXECUTION'
const MINUS_DAY_DATE_EXECUTION = 'MINUS_DAY_DATE_EXECUTION'
//---------------------------------------------------------

const UPDATE_DATASTATE_EXECUTION = 'UPDATE_DATASTATE_EXECUTION'
const ADD_DATASTATE_EXECUTION = 'ADD_DATASTATE_EXECUTION'
const SET_DATASTATE_EXECUTION = 'SET_DATASTATE_EXECUTION'
const DELETE_DATASTATE_EXECUTION = 'DELETE_DATASTATE_EXECUTION'
//--------------------------------------------------------
const MODAL_CONFIRMATION_EXECUTION = 'MODAL_CONFIRMATION_EXECUTION'
const RESET_MODAL_CONFIRMATION_EXECUTION = 'RESET_MODAL_CONFIRMATION_EXECUTION'

const SET_TYPE_VIEW_EXECUTION = 'SET_TYPE_VIEW_EXECUTION'

const SET_IS_TAB_SR_VALID = 'SET_IS_TAB_SR_VALID'

export {
  SET_DATE_EXECUTION,
  SET_IS_TAB_SR_VALID,
  ADD_DAY_DATE_EXECUTION,
  MINUS_DAY_DATE_EXECUTION,
  //-------------------
  ADD_DATASTATE_EXECUTION,
  SET_DATASTATE_EXECUTION,
  UPDATE_DATASTATE_EXECUTION,
  DELETE_DATASTATE_EXECUTION,
  //-------------------
  MODAL_CONFIRMATION_EXECUTION,
  RESET_MODAL_CONFIRMATION_EXECUTION,
  //-------------------
  SET_TYPE_VIEW_EXECUTION,
}
