import axios from 'axios'

import { Config, Header } from '../../config.api'

const GetListDataVessel = async (skip, take, filterString) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/vessels?skip=${skip}&take=${take}&filter=${filterString}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const GetListVesselCategory = async () => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/enums?skip=0&take=20&filter=[{"type":"text","field":"type","value":"vessel_category"},{"type":"bool","field":"is_active","value":"true"}]`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const GetListVesselType = async () => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/vessel_types?skip=0&take=20&filter=[]`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const GetListVesselSize = async () => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/enums?skip=0&take=20&filter=[{"type":"text","field":"type","value":"vessel_size"},{"type":"bool","field":"is_active","value":"true"}]`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const GetAttachmentFile = async (filename) => {
  try {
    const response = await axios.get(
      `${Config.ATTACHMENT_SERVICE_ENDPOINT}/uploader/download?filename=${encodeURIComponent(filename)}`
    )
    return response
  } catch (error) {
    return error
  }
}

const UploadAttachmentFile = async (formData) => {
  try {
    const response = await axios.post(
      `${Config.ATTACHMENT_SERVICE_ENDPOINT}/uploader/single`,
      formData,
      { headers: {
        'Content-Type': 'multipart/form-data',
      } }
    )
    return response
  } catch (error) {
    return error
  }
}

const PostDataVessel = async (formData) => {
  try {
    const response = await axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/vessel/insert`,
      formData,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const EditDataVessel = async (formData) => {
  try {
    const response = await axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/vessel/update`,
      formData,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const DelDataVessel = async (delData) => {
  try {
    const response = await axios.delete(
      `${Config.MAIN_SERVICE_ENDPOINT}/vessel/delete/${delData}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}



export default {
  DelDataVessel,
  PostDataVessel,
  EditDataVessel,
  GetListDataVessel,
  GetListVesselType,
  GetListVesselSize,
  GetAttachmentFile,
  UploadAttachmentFile,
  GetListVesselCategory,
}
