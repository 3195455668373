export const checkIsEmpty = (text) => {
  return text.trim().length === 0
}

export const sortStringArray = (array, field) => {
  return array.sort((a, b) =>
    a[`${field}`].toLowerCase() > b[`${field}`].toLowerCase()
      ? 1
      : b[`${field}`].toLowerCase() > a[`${field}`].toLowerCase()
        ? -1
        : 0
  )
}
