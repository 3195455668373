import Axios from 'axios'
import { Config, Header } from '../config.api'

const GetAllDataServiceRequest = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/service_requests?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}
const GetAllDataServiceRequestCount = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/service_requests_count?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAllMasterDataAgent = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/agents?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAllMasterDataClient = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/clients?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAllMasterDataVessel = async (skip, take, filterString) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/vessels?skip=${skip}&take=${take}&filter=${filterString}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAllLocation = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/locations?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAllServiceRequestType = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/sr_types?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAllMasterProducts = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/products?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAllSrProducts = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/sr_products?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAllFromTableEnum = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/enums?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetSRCategory = async () => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/sr_category`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetSRStatus = async () => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/sr_status`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAllAttachment = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/attachments_sr?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAttachmentFile = async (filename) => {
  try {
    const response = await Axios.get(
      `${
        Config.ATTACHMENT_SERVICE_ENDPOINT
      }/uploader/download?filename=${encodeURIComponent(filename)}`
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAttachmentFileSR = async (filename) => {
  try {
    const response = await Axios.get(
      `${
        Config.ATTACHMENT_SERVICE_ENDPOINT
      }/uploader/download-sr?filename=${encodeURIComponent(filename)}`
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const UploadAttachments = async (data) => {
  return Axios.post(
    `${Config.ATTACHMENT_SERVICE_ENDPOINT}/uploader/multiple`,
    data,
    await Header()
  )
}

const Insert = async (data) => {
  try {
    const response = await Axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/service_request/insert`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const InsertMany = async (data) => {
  try {
    const response = await Axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/service_request/insert_many`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const Delete = async (id) => {
  try {
    const response = await Axios.delete(
      `${Config.MAIN_SERVICE_ENDPOINT}/service_request/delete/${id}`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const UpdateMany = async (data) => {
  try {
    const response = await Axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/service_request/update_many`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetColumnOption = async () => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/column_option/v_t_service_requests`
    const response = await Axios.get(url, await Header())
    return response
  } catch (error) {
    console.log(error)
  }
}

const UpdateColumnOption = async (columns) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/column_option`
    const response = await Axios.post(url, columns, await Header())
    return response
  } catch (error) {
    console.log(error)
  }
}

const InsertAlldoc = async (data) => {
  try {
    const response = await Axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/alldoc/insert`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const CancelSr = async (data) => {
  try {
    const response = await Axios.put(
      `${Config.WORKFLOW_SERVICE_ENDPOINT}/approval/cancelrStatus`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetUomTypes = async () => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/uomtype`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const RejectSr = async (data) => {
  try {
    const response = await Axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/service_request/reject`,
      data,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const SubmitConfirm = async (data) => {
  try {
    const response = await Axios.put(
      `${Config.WORKFLOW_SERVICE_ENDPOINT}/approval/confirmSrStatus`,
      data,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const GetHistoryProgress = async (srNo) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/progress_status?skip=0&take=100&filter=[{ "field": "sr_no", "type": "text", "value": "${srNo}" }]`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetSrByEmail = async (srNo) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/service_requests?skip=0&take=100&filter=[{ "field": "sr_no", "type": "text", "value": "${srNo}" }]`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetMasterWorkPermit = async () => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/service_request/work-permit`
    const response = await Axios.get(url, await Header())

    return response
  } catch (error) {
    console.log('eror get master work permit', error)
  }
}

const GetAllMasterData = async () => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/master_data`
    const request = {
      action: 'GET_ALL_MASTER_DATA_SR'
    }
    const response = await Axios.post(url, request, await Header())

    return response
  } catch (error) {
    console.log('eror get master work permit', error)
  }
}

const GetSrField = async (srTypeCode, srCategoryCode) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/administrative-tools`
    const request = {
      action: 'GET_SR_FIELD',
      query: {
        skip: 0,
        take: 100,
        filters: [
          {
            field: 'is_active',
            type: 'bool',
            value: 'true'
          }
        ],
        orders: [
          {
            field: 'sort',
            dir: 'asc'
          }
        ]
      },
      data: {
        SR_Type_Code: srTypeCode,
        SR_Field_Header_Code: srCategoryCode
      }
    }
    const response = await Axios.post(url, request, await Header())
    return response
  } catch (err) {
    console.log(err)
  }
}

const GetDetailServiceRequest = async (code) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/service_request/detail/${code}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export default {
  GetUomTypes,
  GetSRCategory,
  GetSRStatus,
  GetAttachmentFile,
  CancelSr,
  Delete,
  Insert,
  RejectSr,
  InsertMany,
  UpdateMany,
  InsertAlldoc,
  GetSrByEmail,
  SubmitConfirm,
  UploadAttachments,
  GetAllAttachment,
  GetAllLocation,
  GetHistoryProgress,
  GetAllDataServiceRequest,
  GetAllDataServiceRequestCount,
  GetAllMasterDataAgent,
  GetAllMasterDataClient,
  GetAllMasterDataVessel,
  GetAllServiceRequestType,
  GetAllSrProducts,
  GetAllMasterProducts,
  GetAllFromTableEnum,
  GetColumnOption,
  UpdateColumnOption,
  GetSrField,
  GetMasterWorkPermit,
  GetAllMasterData,
  GetAttachmentFileSR,
  GetDetailServiceRequest
}
