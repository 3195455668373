import { SET_IS_TAB_SR_VALID } from '../../actions'

const initialState = {
  isValid: false
}

const executionValidateSRFormReducer = (state = initialState, action) => {
  switch (action.type) {
  case SET_IS_TAB_SR_VALID:
    return { ...state, isValid: action.payload }
  default:
    return state
  }
}

export default executionValidateSRFormReducer
