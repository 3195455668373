import moment from 'moment'
import getConfig from 'next/config'
import authService from '../utils/authservice'

const { publicRuntimeConfig } = getConfig()

const Config = {
  MAIN_SERVICE_ENDPOINT:
    process.env.MAIN_SERVICE_ENDPOINT || publicRuntimeConfig.MAIN_SERVICE_ENDPOINT,
  MAIL_SERVICE_ENDPOINT:
    process.env.MAIL_SERVICE_ENDPOINT || publicRuntimeConfig.MAIL_SERVICE_ENDPOINT,
  ATTACHMENT_SERVICE_ENDPOINT:
    process.env.ATTACHMENT_SERVICE_ENDPOINT || publicRuntimeConfig.ATTACHMENT_SERVICE_ENDPOINT,
  ROLE_MANAGEMENT_ENDPOINT:
    process.env.ROLE_MANAGEMENT_ENDPOINT || publicRuntimeConfig.ROLE_MANAGEMENT_ENDPOINT,
  APPLICATION_ID: process.env.APPLICATION_ID || publicRuntimeConfig.APPLICATION_ID,
  WORKFLOW_SERVICE_ENDPOINT:process.env.WORKFLOW_SERVICE_ENDPOINT || publicRuntimeConfig.WORKFLOW_SERVICE_ENDPOINT,
  LOG_SERVICE_ENDPOINT:process.env.LOG_SERVICE_ENDPOINT || publicRuntimeConfig.LOG_SERVICE_ENDPOINT,
  LINTAS_API:process.env.LINTAS_API || publicRuntimeConfig.LINTAS_API,

}

const Header = async () => {
  const token = await _token()
  return await _header(token)
}

const _header = (token) => {
  const user = localStorage.getItem('user_info')
    ? JSON.parse(localStorage.getItem('user_info'))
    : null
  return {
    headers: {
      Email: user ? user.User.Email : 'admin@interport.co.id',
      User: user ? JSON.stringify(user.User) : '',
      'token-auth': token,
    },
  }
}

const _token = async () => {
  const currToken = localStorage.getItem('token') ? localStorage.getItem('token') : ''
  if (currToken !== null || currToken !== '' || currToken !== undefined) {
    const tokenInfo = await authService.getTokenInfo(currToken)
    const expDate = new Date(tokenInfo.payload.exp * 1000)
    if(moment(expDate).diff(moment(), 'minute') < 30) {
      const token = await authService.getTokenSilent()
      if (token) {
        localStorage.setItem('token', token.idToken)
        return token.idToken
      } else {
        return undefined
      }
    }
  } else {
    const token = await authService.getTokenSilent()
    if (token) {
      localStorage.setItem('token', token.idToken)
      return token.idToken
    } else {
      return undefined
    }
  }
  return currToken
}


export { Config, Header }
