import { useState, useEffect } from 'react'

/*
  DOCS: You can use this custom hooks to prevent multiple clicks on a component.
  Args:
    onClick: function to be executed when the component is clicked.

  Returns:
    handleClick: the modified onClick function which prevents multiple clicks.

  Example:
    const CustomButton = ({ disable, isInEdit, handleInsertOrUpdateDelay }) => {
      const handleClick = customHooks.useHandleMultipleClicks(handleInsertOrUpdateDelay)

      return (
        <Button onClick={handleClick} >
          Save
        </Button>
      )
    }
*/
const usePreventMultipleClicks = (onClick) => {
  const [isClicked, setIsClicked] = useState(false)

  const handleClick = () => {
    if (!isClicked) {
      setIsClicked(true)
      onClick()
    }
  }

  // After half a second, user can click again
  useEffect(() => {
    let timer
    if (isClicked) {
      setTimeout(() => {
        setIsClicked(false)
      }, 500)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [isClicked])

  return handleClick
}

export default {
  usePreventMultipleClicks
}