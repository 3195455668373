import { SET_DATE_EXECUTION, ADD_DAY_DATE_EXECUTION, MINUS_DAY_DATE_EXECUTION } from '../../actions'

const initialState = new Date()

const executionDateReducer = (state = initialState, action) => {
  switch (action.type) {
  case SET_DATE_EXECUTION: {
    return action.payload
  }
  case ADD_DAY_DATE_EXECUTION: {
    return new Date(new Date(state).setDate(new Date(state).getDate() + 1))
  }
  case MINUS_DAY_DATE_EXECUTION: {
    return new Date(new Date(state) - 3600 * 1000 * 24)
  }
  default: {
    return state
  }
  }
}

export default executionDateReducer
