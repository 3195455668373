import axios from 'axios'

import { Config, Header } from '../config.api'

const GenerateVapid = async (UserID) => {
  try {
    const response = await axios.post(
      `${Config.MAIL_SERVICE_ENDPOINT}/generate-vapid`,
      null,
      {
        headers: {
          'UserID': UserID ? UserID : ''
        },
      }
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const UpdateSubscription = async (UserID, subscriptionObjectStringified) => {
  try {
    const response = await axios.post(
      `${Config.MAIL_SERVICE_ENDPOINT}/update-subscription`,
      {
        'Subscription': subscriptionObjectStringified
      },
      {
        headers: {
          'UserID': UserID ? UserID : ''
        },
      }
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetHistoryNotif = async (skip, take, filter) => {
  try {
    const response = await axios.get(
        `${Config.WORKFLOW_SERVICE_ENDPOINT}/notification/detail/history?skip=${skip}&take=${take}&filter=${filter}`,
        await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const GetColumnOption = async () => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/column_option/v_t_notifications`
    const response = await axios.get(url, await Header())
    return response
  } catch (error) {
    console.log(error)
  }
}

const UpdateColumnOption = async (columns) => {
  try {
    const url = `${Config.MAIN_SERVICE_ENDPOINT}/column_option`
    const response = await axios.post(url, columns, await Header())
    return response
  } catch (error) {
    console.log(error)
  }
}

export default {
  GenerateVapid,
  GetHistoryNotif,
  GetColumnOption,
  UpdateColumnOption,
  UpdateSubscription,
}


