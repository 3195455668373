import axios from 'axios'

import { Config, Header as HeaderPlain } from '../../config.api'

const Header = async () => {
  const header = await HeaderPlain()
  header.headers['by-pass'] = true
  return header
}

const GetJettyEnum = async () => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/enums?skip=0&take=1000&filter=[{"type":"text","field":"type","value":"Jetty_Maps"}]`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const GetOnProgress = async (filter) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/jetty_maps?filter=${filter}`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const GetNextComing = async (filter) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/jetty_maps_nextcoming?filter=${filter}`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const GetActivity = async (srCode) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/progress_activities?skip=0&take=1000&filter=[{"type":"text","field":"sr_code","value":"${srCode}"}]&sort=id|desc`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const GetDelay = async (srNo) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/progress_delays?skip=0&take=1000&filter=[{"type":"text","field":"sr_no","value":"${srNo}"}]&sort=id|desc`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const GetIntervalDataJetty = async (skip, take, filters) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/enums?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const GetMapsJettyLocation = async (skip, take, filters) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/enums?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

export default { GetJettyEnum, GetOnProgress, GetNextComing, GetActivity, GetDelay, GetIntervalDataJetty, GetMapsJettyLocation }
