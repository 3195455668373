import Axios from 'axios'
import { Config, Header } from '../../config.api'

const GetAllMasterDataClient = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/clients?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const Insert = async (dataItem) => {
  try {
    const response = await Axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/client/insert`, dataItem,
      await Header()
    )

    return response.data
  } catch (error) {
    console.log(error)
  }
}

const Update = async (dataItem) => {
  try {
    const response = await Axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/client/update`, dataItem,
      await Header()
    )

    return response.data
  } catch (error) {
    console.log(error)
  }
}
const Delete = async (Id) => {
  try {
    const response = await Axios.delete(
      `${Config.MAIN_SERVICE_ENDPOINT}/client/delete/${Id}`,
      await Header()
    )

    return response.data
  } catch (error) {
    console.log(error)
  }
}

export default { GetAllMasterDataClient, Insert, Delete, Update }
