const LEGEND_JETTY_MAPS = [
  { label: 'Delay', color: '#FF0000' },
  { label: 'On Progress', color: '#F2C300' },
  { label: 'Complete', color: '#8EBB3C' },
]

const HEADER_JETTY_MAPS = [
  { label: '', field: '', width: '2.5vw', next_width: '0vw', current: true },
  { label: 'Vessel Name', field: '', width: '4vw', next_width: '35vw', current: false },
  { label: 'ATA', field: '', width: '4vw', next_width: '27.5vw', current: false },
  {
    label: 'Planning Depart',
    field: '',
    width: '4vw',
    next_width: '27.5vw',
    current: false,
  },
  { label: 'Progress', field: '', width: '4vw', next_width: '0vw', current: true },
]

const LOG_ACTION = {
  ADD: 'ADD',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
  SUBMIT: 'SUBMIT',
  UPLOAD: 'UPLOAD',
  SAVE_DRAFT: 'SAVE_DRAFT',
}

const LOG_MESSAGE = {
  NO_INPUT: 'all of fields are not filled yet',
}

const LOG_MODULE = {
  MASTER_DATA: 'MASTER_DATA',
  DASHBOARD: 'DASHBOARD',
  SETTLEMENT: 'SETTLEMENT',
  EXECUTION: 'EXECUTION',
  NOTIFICATION: 'NOTIFICATION',
  SERVICE_REQUEST: 'SERVICE_REQUEST',
}

const LOG_SUB_MODULE = {
  DELAY: 'DELAY',
  REVISE: 'REVISE',
  REJECT: 'REJECT',
  PRODUCT: 'PRODUCT',
  CONFIRM: 'CONFIRM',
  DOCUMENT: 'DOCUMENT',
  ACTIVITY: 'ACTIVITY',
  EXECUTION: 'EXECUTION',
  DASHBOARD: 'DASHBOARD',
  SCHEDULER: 'SCHEDULER',
  SETTLEMENT: 'SETTLEMENT',
  MASTER_DATA: 'MASTER_DATA',
  MASTER_DELAY: 'MASTER_DELAY',
  MASTER_JETTY: 'MASTER_JETTY',
  MASTER_VESSEL: 'MASTER_VESSEL',
  ACTIVITY_TASK: 'ACTIVITY_TASK',
  MASTER_APPROVAL: 'MASTER_APPROVAL',
  SERVICE_REQUEST: 'SERVICE_REQUEST',
  MASTER_ACTIVITY: 'MASTER_ACTIVITY',
}

const LOG_TYPE = {
  SYSTEM_LOG: 'SYSTEM_LOG',
  AUDIT_TRAIL: 'AUDIT_TRAIL',
}

const LOG_ERROR_LEVEL = {
  INFO: 'INFO',
  ERROR: 'ERROR',
}
export default {
  LEGEND_JETTY_MAPS,
  HEADER_JETTY_MAPS,
  LOG_ACTION,
  LOG_MODULE,
  LOG_MESSAGE,
  LOG_SUB_MODULE,
  LOG_TYPE,
  LOG_ERROR_LEVEL,
}
