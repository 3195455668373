import { SET_USER_PROFILE, SET_USER_PROFILE_LOCATION } from '../../actions'
const initialState = { id: 0 }

const usersProfileReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
  case SET_USER_PROFILE: {
    let dataUser = JSON.parse(action.payload)

    if (action.payload && action.payload.length > 0) {
      dataUser = JSON.parse(action.payload)
    } else {
      const userInfo = JSON.parse(localStorage.getItem('user_info'))
      dataUser = {
        ...userInfo,
      }
    }

    return { ...dataUser }
  }
  case SET_USER_PROFILE_LOCATION: {
    const dataUserLocation = { ...state, Location: action.payload }
    return { ...dataUserLocation }
  }
  default: {
    return state
  }
  }
}

export default usersProfileReducer
