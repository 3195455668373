import {
  SET_SERVICE_REQUEST_ALL,
  SET_SERVICE_REQUEST_ALL_DETAIL
} from '../../actions'

const initialState = { allSr: [], detailAllSR: {} }

const serviceRequestAllReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_SERVICE_REQUEST_ALL: {
      return { ...state, allSr: [...action.payload] }
    }
    case SET_SERVICE_REQUEST_ALL_DETAIL: {
      return { ...state, detailAllSR: { ...action.payload } }
    }
    default: {
      return state
    }
  }
}

export default serviceRequestAllReducer

