import axios from 'axios'
import { Config, Header } from '../../config.api'

const GetListDataJetty = async (skip, take, filter) => {
  try {
    const response = await axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/jettys?skip=${skip}&take=${take}&filter=${filter}`,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

export const DelDataJetty = async (id) => {
  try {
    const response = await axios.delete(
        `${Config.MAIN_SERVICE_ENDPOINT}/jetty/delete/${id}`,
        await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const PostDataJetty = async (formData) => {
  try {
    const response = await axios.post(
        `${Config.MAIN_SERVICE_ENDPOINT}/jetty/insert`,
        formData,
        await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

export const EditDataJetty = async (formData) => {
  try {
    const response = await axios.put(
      `${Config.MAIN_SERVICE_ENDPOINT}/jetty/update`,
      formData,
      await Header()
    )
    return response
  } catch (error) {
    return error
  }
}

const GetListDataLocation = async () => {
  try {
    const response = await axios.get(
        `${Config.MAIN_SERVICE_ENDPOINT}/locations`,
        await Header()
    )
    return response
  } catch (error) {
    return error
  }
}


export default {
  DelDataJetty,
  PostDataJetty,
  EditDataJetty,
  GetListDataJetty,
  GetListDataLocation
}