import axios from 'axios'
import { Actions } from '../../utils'
import { Config, Header }  from '../config.api'

const GetDataContract = async (code) => {
  try {
    const response = await axios.post(
            `${Config.LINTAS_API}/auth/contracts`,
            {
              action: Actions.GET_ALL_CONTRACT,
              query: {
                skip: 0,
                take: 1000,
                filters: [
                  {
                    type: 'text',
                    field: 'client',
                    value: code
                  }, {
                    type: 'boolean',
                    field: 'contracts.status',
                    value: 'TRUE'
                  }
                ],
                order: ''
              }
            },
            await Header()
    )
    return response
  } catch (error) {
    return { error }
  }
}

export default {
  GetDataContract
}
