const SET_USER_LOGIN = 'SET_USER_LOGIN'
const SET_USER_TIMEOUT = 'SET_USER_TIMEOUT'
const SET_USER_PROFILE = 'SET_USER_PROFILE'
const SET_USER_PROFILE_LOCATION = 'SET_USER_PROFILE_LOCATION'
const SET_USER_ROLE_MAPPING = 'SET_USER_ROLE_MAPPING'

export {
  SET_USER_PROFILE,
  SET_USER_LOGIN,
  SET_USER_ROLE_MAPPING,
  SET_USER_TIMEOUT,
  SET_USER_PROFILE_LOCATION,
}
