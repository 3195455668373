import { ExecutionAPI } from '../api'

export const prefix = (filename) => {
  let filenameExt = filename.split('.')[filename.split('.').length - 1].toLowerCase()

  if (formatPdf.includes(filenameExt)) {
    return 'data:application/pdf;base64,'
  }
  if (fortmatDoc.includes(filenameExt)) {
    return 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,'
  }
  if (formatExcel.includes(filenameExt)) {
    return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
  }
  if (imageJPG.includes(filenameExt)) {
    return 'data:image/jpeg;base64,'
  }

  if (imagePNG.includes(filenameExt)) {
    return 'data:image/png;base64,'
  }
}

const formatPdf = ['pdf']
const fortmatDoc = ['doc', 'docx', 'docm', 'docb', 'dotx', 'dot', 'wbk', 'dotx', 'dotm', 'docb']
const formatExcel = [
  'xls',
  'xlt',
  'xlm',
  'xlsx',
  'xlsm',
  'xltx',
  'xltm',
  'xlsb',
  'xla',
  'xlam',
  'xll',
  'xlw',
]
const imageJPG = ['jpg', 'jpeg', 'jpe', 'jif', 'jfif', 'jfi']
const imagePNG = ['png']

export const setToBase64 = async (filename) => {
  let dataBase64 = ''

  if (filename?.length > 0) {
    const response = await ExecutionAPI.GetAttachmentFile(filename)
    let baseFile64
    let prefixBase64
    if (response) {
      baseFile64 = response.data
      prefixBase64 = prefix(filename)
      dataBase64 = prefixBase64 + baseFile64
    }
  }
  return dataBase64
}

export const setToBase64SR = async (filename) => {
  let dataBase64 = ''

  if (filename?.length > 0) {
    const response = await ExecutionAPI.GetAttachmentFileSR(filename)
    let baseFile64
    let prefixBase64
    if (response) {
      baseFile64 = response.data
      prefixBase64 = prefix(filename)
      dataBase64 = prefixBase64 + baseFile64
    }
  }
  return dataBase64
}
