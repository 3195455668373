import moment from 'moment'

export function GenerateFilterTerm(filter, arrcolumn) {
  let filterTerm = ''

  if (filter !== null && filter.filters !== undefined) {
    filter.filters
      .filter((f) => f.value !== '')
      .map((item, index) => {
        const koma = index > 0 ? ',' : ''
        if (arrcolumn.find((e) => e.field === item.field).filterType === 'date') {
          const startOfDay = moment(item.value).format('YYYY-MM-DD')

          if (startOfDay.toLowerCase() !== 'invalid date') {
            filterTerm = `${filterTerm}${koma}{"field":"${item.field}","type":"date","value":"${startOfDay}"}`
          }
        } else {
          filterTerm = `${filterTerm}${koma}{"field":"${item.field}","type":"${
            arrcolumn.find((e) => e.field === item.field).filterType
          }","value":"${item.value}"}`
        }

        return item
      })
    filterTerm = `[${filterTerm}]`
  } else {
    filterTerm = '[]'
  }
  return filterTerm
}
