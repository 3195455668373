export const messageErrorFormikLog = (errors) => {
  let messages = ''
  let tempArr = Object.entries(errors)
  tempArr.map((e) => {
    const comma = messages.length > 0 ? ', ' : ''
    if (e.length >= 2) {
      messages = messages + comma + e[0] + ' is ' + e[1]
    } else {
      messages = messages + ' ' + e[0]
    }
  })

  return messages
}

export const messageErrorApproval = (errors) => {
  let messages = ''
  if (errors.indexOf('approval client not found') >= 0) {
    messages = 'Approval not found, please check master approval or head client approver'
  } else if (errors.indexOf('approval user not found') >= 0) {
    messages = 'Approval not found, please check master approval or head client approver'
  } else if (errors.indexOf('approval finalized not found') >= 0) {
    messages = 'Approval not found, please check creator or or head client approver'
  } else if (errors.indexOf('approval role mapping not found') >= 0) {
    messages = 'Approval not found, please check role mapping'
  } else if (errors.indexOf('approval pic not found') >= 0) {
    messages = 'Approval not found, please check master pic'
  } else {
    messages = 'Oops! Something went wrong. Please try again later.'
  }

  return messages
}