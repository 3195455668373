import { SET_TYPE_VIEW_EXECUTION } from '../../actions'
const initialState = 'day'

const executionViewType = (state = initialState, action) => {
  switch (action.type) {
  case SET_TYPE_VIEW_EXECUTION:
    return action.payload
  default: {
    return state
  }
  }
}

export default executionViewType
