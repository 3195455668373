import Axios from 'axios'
import { Config, Header } from '../../config.api'

const GetAllMasterDataAgent = async (skip, take, filters) => {
  try {
    const response = await Axios.get(
      `${Config.MAIN_SERVICE_ENDPOINT}/agents?skip=${skip}&take=${take}&filter=${filters}`,
      await Header()
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const GetAgentType = async () => {
  try {
    const response = await Axios.get(`${Config.MAIN_SERVICE_ENDPOINT}/agent_types`, await Header())
    return response.data
  } catch (error) {
    return error
  }
}

const InsertAgent = async (data) => {
  try {
    const response = await Axios.post(
      `${Config.MAIN_SERVICE_ENDPOINT}/agent/insert`,
      data,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

const UpdateAgent = async (data) => {
  try {
    const response = await Axios.put(`${Config.MAIN_SERVICE_ENDPOINT}/agent/update`, data, await Header())
    return response.data
  } catch (error) {
    return error
  }
}

const DeleteAgent = async (id) => {
  try {
    const response = await Axios.delete(
      `${Config.MAIN_SERVICE_ENDPOINT}/agent/delete/${id}`,
      await Header()
    )
    return response.data
  } catch (error) {
    return error
  }
}

export default { GetAllMasterDataAgent, GetAgentType, InsertAgent, UpdateAgent, DeleteAgent }
