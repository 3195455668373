import {
  ADD_DATASTATE_EXECUTION,
  SET_DATASTATE_EXECUTION,
  UPDATE_DATASTATE_EXECUTION,
  DELETE_DATASTATE_EXECUTION,
} from '../../actions'

const initialState = []

const executionDataStateReducer = (state = initialState, action) => {
  switch (action.type) {
  case SET_DATASTATE_EXECUTION: {
    return action.payload
  }
  case UPDATE_DATASTATE_EXECUTION: {
    const newState = state.map((e) => {
      if (e.Code === action.payload.Code) {
        return action.payload
      }
      return e
    })
    return newState
  }
  case ADD_DATASTATE_EXECUTION: {
    return [...state, action.payload]
  }
  case DELETE_DATASTATE_EXECUTION: {
    const newState = state.filter((e) => {
      if (e.Code !== action.payload.Code) {
        return e
      }
      return
    })
    return newState
  }

  default: {
    return state
  }
  }
}

export default executionDataStateReducer
