import executionDateReducer from './date.execution.reducers'
import executionViewType from './view-type.execution.reducers'
import executionDataStateReducer from './datastate.execution.reducers'
import executionValidateSRFormReducer from './validate-sr-form.reducers'
import executionModalConfirmReducer from './modal.confirm.execution.reducers'

export {
  executionViewType,
  executionDateReducer,
  executionDataStateReducer,
  executionModalConfirmReducer,
  executionValidateSRFormReducer,
}
